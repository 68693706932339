
import StatusSave from './StatusSave.vue';
import StatusSaveInfo from './StatusSaveInfo.vue';
import { StatusTheme } from '@/enums';

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StatusSaveTemplate',
  components: {
    StatusSave,
    StatusSaveInfo,
  },
  props: {
    statusSaving: {
      type: String,
      default: StatusTheme.SAVED,
    },
  },
  emits: ['try-again'],
  data() {
    return {
      status: StatusTheme.SAVED,
      showPopupError: false,
    };
  },
  watch: {
    statusSaving(): any {
      this.showPopupIfError();
    },
  },
  mounted() {
    this.status = this.statusSaving;
  },
  methods: {
    tryAgain(): void {
      this.showPopupError = false;
      console.info('Not yet');
    },
    showPopupIfError(): void {
      this.showPopupError = false;
      if (this.statusSaving !== StatusTheme.ERROR) return;
      this.showPopupError = true;
      setTimeout(() => {
        this.showPopupError = false;
      }, 4000);
    },
  },
});
