import { InputFile } from './InputFile.model';

export function useInputFile(
  label: string,
  type: string,
  description: string[],
  field: string,
  value: string | undefined,
  validation: string,
  tab: string
): InputFile {
  return new InputFile({
    label,
    type,
    description,
    field,
    value,
    validation,
    tab,
  });
}
