
import { defineComponent } from 'vue';
import Tooltip from '@/components/core/Tooltip.vue';
import { PaymentStatus } from '@/enums';

export default defineComponent({
  name: 'ThemeTagsThumb',
  components: {
    Tooltip,
  },
  props: {
    updated: {
      type: Boolean,
      default: false,
    },
    paid: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      PaymentStatus,
    };
  },
});
