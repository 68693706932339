import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-edit-theme" }
const _hoisted_2 = { class: "modal-edit-theme__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    "class-style": "modal modal-height-edit-theme",
    "label-button": _ctx.$t('components.modals.button.continue'),
    title: _ctx.$t('components.modals.titles.attention'),
    "type-button": "continue",
    onContinue: _ctx.themeEdit
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('components.modals.edit-theme.description')), 1),
        _createVNode(_component_Alert, {
          "class-style": "alert modal-edit-theme__alert",
          "input-fields": {
          label: _ctx.$t('components.alert.suggestion'),
          description: _ctx.$t('components.alert.changes-edit-theme'),
        },
          icon: true
        }, null, 8, ["input-fields"])
      ])
    ]),
    _: 1
  }, 8, ["label-button", "title", "onContinue"]))
}