
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue';
import LogoSealSidebar from '@/components/sidebar/LogoSealSidebar.vue';

export default defineComponent({
  name: 'ThemeLogoSeals',
  components: {
    Icon,
    LogoSealSidebar,
  },
  props: {
    showLogo: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('themeEditor', {
      activeStoreLogo: 'imageActiveLogo',
    }),
    buttonShowLogo(): string {
      if (this.showLogo) return this.$t('components.sidebar.edit-logo-seals');
      return this.$t('components.theme-display.logo-seals.add-logo-seals');
    },
  },
  methods: {
    openSidebarLogoSeal(): void {
      this.$refs.sidebarLogoSeal.open();
    },
  },
});
