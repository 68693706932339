import {
  ImageApiClientUrlsInterface,
  ImageApiClientInterface,
  ImageApiClientModel,
} from '@/models/api-client/image';

import { config } from '@/config';

const urls: ImageApiClientUrlsInterface = config.images.apiUrls;
const ImageApiClient: ImageApiClientInterface = new ImageApiClientModel(urls);
export default ImageApiClient;
