import { ref, Ref } from 'vue';

type ComponentName =
  | 'SettingsEditor'
  | 'LogoEditor'
  | 'ButtonEditor'
  | 'SealEditor'
  | 'SessionsEditor';

interface UseEditorSidebar {
  componentName: Ref<ComponentName>;
  setComponentName(name: ComponentName): void;
  resetComponentName(): void;
}

const componentName = ref<ComponentName>('SettingsEditor');

export default function useSidebarEditor(): UseEditorSidebar {
  function setComponentName(name: ComponentName): void {
    componentName.value = name;
  }

  function resetComponentName(): void {
    componentName.value = 'SettingsEditor';
  }

  return {
    componentName,
    setComponentName,
    resetComponentName,
  };
}
