import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "theme-display-empty__wrapper" }
const _hoisted_2 = { class: "theme-display-empty__title" }
const _hoisted_3 = { class: "theme-display-empty__description" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ThemeDisplayEmptyButton = _resolveComponent("ThemeDisplayEmptyButton")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(
      _ctx.buttonThemeStore
        ? _ctx.listUnpublished
          ? 'theme-display-empty theme-display-empty-list'
          : 'theme-display-empty'
        : 'theme-display-empty theme-display-empty-search'
    )
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_Icon, {
        class: "theme-display-empty__icon-store-slash",
        name: "store-slash",
        prefix: "fas"
      })
    ]),
    _createElementVNode("section", {
      class: _normalizeClass(
        _ctx.buttonThemeStore
          ? 'theme-display-empty__container'
          : 'theme-display-empty__container-search'
      )
    }, [
      _createElementVNode("article", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]),
      (_ctx.buttonThemeStore)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "theme-display-empty__link-redirect",
            href: _ctx.urlThemeStore,
            target: "_blank"
          }, [
            _createVNode(_component_ThemeDisplayEmptyButton, {
              label: _ctx.$t('components.theme-display-empty.theme-store'),
              class: "theme-display-empty-button__outlined theme-display-empty-button__lg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  name: "cart-plus",
                  prefix: "fas"
                })
              ]),
              _: 1
            }, 8, ["label"])
          ], 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "theme-display-empty__link-clear-filter",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear-filter')))
          }, _toDisplayString(_ctx.$t('components.theme-display-empty.clear-filter-and-view-list')), 1))
    ], 2)
  ], 2))
}