import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__input-radio" }
const _hoisted_2 = { class: "tray-theme-editor__input-radio__title" }
const _hoisted_3 = { class: "tray-theme-editor__input-radio__description" }
const _hoisted_4 = { class: "tray-theme-editor__input-radio__section--options" }
const _hoisted_5 = ["id", "value", "data-selected"]
const _hoisted_6 = { class: "tray-theme-editor__input-radio__section--content" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "tray-theme-editor__input-radio__description" }
const _hoisted_9 = {
  key: 0,
  class: "tray-theme-editor__input-radio__section--image"
}
const _hoisted_10 = ["id", "src", "alt"]
const _hoisted_11 = { class: "tray-theme-editor__input-radio__section--zoom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoomImageModal = _resolveComponent("ZoomImageModal")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ZoomImageModal, {
      ref: "modalZoomImage",
      "url-image": _ctx.urlImage
    }, null, 8, ["url-image"]),
    _createElementVNode("form", {
      class: _normalizeClass(_ctx.tab)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "tray-theme-editor__input-radio__section"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                id: option.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
                value: option.value,
                type: "radio",
                "data-selected": option.value === _ctx.inputValue ? 'true' : 'false',
                class: "tray-theme-editor__input-radio__content"
              }, null, 8, _hoisted_5), [
                [_vModelRadio, _ctx.inputValue]
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("label", {
                  class: _normalizeClass(["tray-theme-editor__input-radio__label", option.style === true ? 'line-through' : '']),
                  for: option.value
                }, _toDisplayString(option.content), 11, _hoisted_7),
                _createElementVNode("span", _hoisted_8, _toDisplayString(option.description), 1)
              ])
            ]),
            (option.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("img", {
                    id: option.url,
                    src: option.url,
                    alt: option.content
                  }, null, 8, _hoisted_10),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_Icon, {
                      prefix: "fas",
                      name: "search-plus",
                      "aria-hidden": "true",
                      onClick: ($event: any) => (_ctx.zoomImage(option.url))
                    }, null, 8, ["onClick"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 64))
}