import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unpublished-theme-button-preview__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "unpublished-theme-button-preview" }, _ctx.$attrs), [
    _createElementVNode("button", {
      class: "unpublished-theme-button-preview__button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('theme-preview')))
    }, [
      _createVNode(_component_Icon, {
        class: "tray-theme-editor__icon",
        name: "play",
        prefix: "fas"
      }),
      _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1)
    ])
  ], 16))
}