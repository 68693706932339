import { InputStandardImages } from './';

export function useInputFile(
  label: string,
  type: string,
  description: string[],
  field: string,
  validation: string,
  tab: string
): InputStandardImages {
  return new InputStandardImages({
    label,
    type,
    description,
    field,
    validation,
    tab,
  });
}
