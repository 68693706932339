import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__navigation-theme" }
const _hoisted_2 = { class: "tray-theme-editor__navigation-theme__wrapper-left" }
const _hoisted_3 = { class: "tray-theme-editor__navigation-theme__title" }
const _hoisted_4 = { class: "tray-theme-editor__navigation-theme__wrapper-right" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Tooltip, {
        info: _ctx.$t('top-navigation.tooltip.back-to-panel'),
        icon: false,
        "position-top": "initial",
        placement: "bottom-bar"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, {
            name: "arrow-left",
            prefix: "fa",
            class: "tray-theme-editor__navigation-theme__icon",
            onClick: _ctx.redirectToPainel
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["info"]),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('top-navigation.appearance-store')), 1)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("a", {
        id: "view-store",
        class: "tray-theme-editor__navigation-theme__view-store",
        href: _ctx.urlViewStore,
        target: "_blank"
      }, [
        _createVNode(_component_Icon, {
          name: "play",
          prefix: "fa"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('top-navigation.view-store')), 1)
      ], 8, _hoisted_5),
      _createElementVNode("a", {
        id: "theme-store",
        class: "tray-theme-editor__navigation-theme__store",
        href: _ctx.urlThemeStore,
        target: "_blank"
      }, [
        _createVNode(_component_Icon, {
          class: "tray-theme-editor__icon",
          name: "cart-plus",
          prefix: "fa"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('top-navigation.store-themes')), 1)
      ], 8, _hoisted_6)
    ])
  ]))
}