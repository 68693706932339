import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-theme__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({ class: "button-theme" }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }), [
    (_ctx.icon)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ], 16))
}