import { InputColorInterface } from './InputColor.interface';

export class InputColor implements InputColorInterface {
  public label = '';
  public type = '';
  public description = [];
  public placeholder = '';
  public field = '';
  public validation = '';
  public tab = '';
  public value = '';

  constructor(dto: InputColorInterface) {
    Object.assign(this, dto);
  }
}
