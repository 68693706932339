import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal__footer" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("button", {
      class: "modal__button modal__button--cancel",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel', 'cancel')))
    }, _toDisplayString(_ctx.$t('components.modals.button.cancel')), 1),
    (_ctx.typeButton === 'delete')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "modal__button modal__button--delete",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete', 'delete')))
        }, _toDisplayString(_ctx.labelButton), 1))
      : (_ctx.typeButton === 'continue')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            disabled: _ctx.isDisabled,
            class: "modal__button modal__button--continue",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('continue', 'continue')))
          }, _toDisplayString(_ctx.labelButton), 9, _hoisted_2))
        : (_ctx.typeButton === 'save')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Tooltip, {
                "show-tooltip": _ctx.hideTooltip,
                info: _ctx.info,
                icon: false,
                "position-top": "center-max",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: "modal__button modal__button--save",
                    disabled: _ctx.isDisabled,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('save', 'save')))
                  }, _toDisplayString(_ctx.labelButton), 9, _hoisted_4)
                ]),
                _: 1
              }, 8, ["show-tooltip", "info"])
            ]))
          : _createCommentVNode("", true)
  ]))
}