import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "theme-tags" }
const _hoisted_2 = {
  key: 0,
  class: "theme-tags__update"
}
const _hoisted_3 = {
  key: 1,
  class: "theme-tags__footer"
}
const _hoisted_4 = { class: "theme-tags__awaiting-payment" }
const _hoisted_5 = {
  key: 2,
  class: "theme-tags__footer"
}
const _hoisted_6 = { class: "theme-tags__payment-canceled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        (!_ctx.updated && _ctx.paid) ||
        (!_ctx.updated && !_ctx.paid && _ctx.payment.status != _ctx.PaymentStatus.INVOICE_CANCELED)
      )
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('components.unpublished-list-themes.theme-item.tags.update')), 1))
      : _createCommentVNode("", true),
    (
        !_ctx.paid &&
        _ctx.payment.status != _ctx.PaymentStatus.THEME_FOR_SALE &&
        _ctx.payment.status != _ctx.PaymentStatus.INVOICE_CANCELED
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(
          'components.unpublished-list-themes.theme-item.tags.awaiting-payment'
        )), 1),
          _createVNode(_component_Tooltip, {
            class: "theme-tags__tooltip",
            label: 
          _ctx.$t(
            'components.unpublished-list-themes.theme-item.tags.message-awaiting-payment'
          )
        ,
            theme: "dark",
            position: "top"
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.paid && _ctx.payment.status == _ctx.PaymentStatus.INVOICE_CANCELED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(
          'components.unpublished-list-themes.theme-item.tags.payment-canceled'
        )), 1),
          _createVNode(_component_Tooltip, {
            class: "theme-tags__tooltip",
            label: 
          _ctx.$t(
            'components.unpublished-list-themes.theme-item.tags.message-payment-canceled'
          )
        ,
            theme: "dark",
            position: "top"
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}