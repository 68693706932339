import { AxiosRequestConfig } from 'axios';
import { RequestInterceptor } from '.';
import { getQueryParam } from '@/helpers';
import router from '@/router';

export interface ParseJwtInterface {
  store: string;
  adm_user: string;
  url: string;
  opencode_theme: string;
  theme_id: string;
}

export default class Auth implements RequestInterceptor {
  private count = 0;
  private getToken(): string {
    return (
      getQueryParam(window.location.href, 'token') ||
      localStorage.getItem('token') ||
      ''
    );
  }

  public getStoreId(): number {
    const token = this.getToken();
    const data = this.parseJwt(token);
    if (Object.keys(data).length === 0) {
      return 0;
    }
    return parseInt(data.store);
  }

  public getCurrentUser(): string {
    const token = this.getToken();
    const data = this.parseJwt(token);
    if (Object.keys(data).length === 0) {
      return '';
    }
    return data.adm_user;
  }

  public parseJwt(token: string): ParseJwtInterface {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public handle(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
    const authToken = this.getToken();
    if (authToken) {
      requestConfig.headers.Authorization = `Bearer ${authToken}`;
      localStorage.setItem('token', authToken);
      if (this.count <= 1) {
        this.count++;
        router.replace({});
      }
    }

    return requestConfig;
  }
}
