
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'ThemeInfo',
  computed: {
    ...mapGetters('themeEditor', {
      theme: 'themeGetSelected',
      info: 'themeGetSelectedInfo',
    }),
  },
});
