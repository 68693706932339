import { useParseToken } from '@/support/useParseToken';
import { MutationsInterface, Mutations } from '@/models/store/user';
import router from '@/router';
import { persistToken, persistStoreUser } from '@/composable';

const mutations: MutationsInterface = {
  [Mutations.SET_STORE_CONFIG]: (state) => {
    const tokenPersist = persistToken();
    let token = router.currentRoute.value.query.token as string;
    if (!token) {
      token = tokenPersist.restore();
    }
    localStorage.clear();
    if (!token) {
      throw new Error('Token not found');
    }
    tokenPersist.save(token);
    const parser = useParseToken();
    const userData = parser.decode(token);
    state.user.username = userData.adm_user;
    state.store.code = userData.store;
    state.store.urlPreview = `https://${userData.store}.commercesuite.com.br`;
    state.store.urlStore = userData.url;
    state.tokenParams.themeCode = userData.opencode_theme;
    state.tokenParams.themeId = userData.theme_id;
    state.urls.panel = `${state.store.urlStore}/admin`;
    state.urls.panelListThemes = `${state.store.urlStore}/mvc/adm/design`;
    state.isLogged = true;
    persistStoreUser().save(state);
    const redirectTo =
      userData.theme_id && state.isLogged ? 'Home' : 'ThemeListPage';
    const params = userData.theme_id
      ? { id: userData.theme_id, page: 'home' }
      : {};
    void router.push({ name: redirectTo, params: params });
  },
  [Mutations.RECOVERY_USER_STORE_DATA]: (state) => {
    const userStore = persistStoreUser().restore();
    if (!userStore) return;
    if (router.currentRoute.value.name !== 'PreFetchPage') {
      state.user = userStore.user;
      state.store = userStore.store;
      state.tokenParams = userStore.tokenParams;
      state.urls = userStore.urls;
      state.isLogged = true;
    }
  },
};

export default mutations;
