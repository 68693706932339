import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Suspense as _Suspense, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fallback-loading" }
const _hoisted_2 = { class: "tray-theme-editor__back-step-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_StoreInfo = _resolveComponent("StoreInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isPreview)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          info: _ctx.$t('navigation.back-list-themes'),
          icon: false,
          "position-top": "initial",
          placement: "bottom-bar"
        }, {
          default: _withCtx(() => [
            _createElementVNode("section", {
              class: "tray-theme-editor__breadcrumbs",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToPage('ThemeListPage')))
            }, [
              _createVNode(_component_Icon, {
                class: "tray-theme-editor__breadcrumb-icon",
                name: "arrow-left",
                prefix: "fa"
              })
            ])
          ]),
          _: 1
        }, 8, ["info"]))
      : _createCommentVNode("", true),
    (!_ctx.isPreview)
      ? (_openBlock(), _createBlock(_Suspense, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_StoreInfo)
          ]),
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('template.loading')), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("section", {
          key: 2,
          class: "tray-theme-editor__section--spaced tray-theme-editor__back-step",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redirectToPage('Home')))
        }, [
          _createVNode(_component_Tooltip, {
            info: _ctx.$t('navigation.back-to-editor'),
            icon: false,
            "position-top": "initial-preview",
            placement: "bottom-bar"
          }, {
            default: _withCtx(() => [
              _createElementVNode("section", {
                class: "tray-theme-editor__breadcrumbs",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectToPage('Home')))
              }, [
                _createVNode(_component_Icon, {
                  class: "tray-theme-editor__breadcrumb-icon",
                  name: "arrow-left",
                  prefix: "fa"
                })
              ])
            ]),
            _: 1
          }, 8, ["info"]),
          _createElementVNode("strong", _hoisted_2, _toDisplayString(_ctx.$t('navigation.back-to-editor')), 1)
        ]))
  ], 64))
}