import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__content-subsession" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      key: _ctx.titleSubsession,
      class: "tray-theme-editor__subsession-button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setDropDown && _ctx.setDropDown(...args)), ["prevent"]))
    }, [
      _createTextVNode(_toDisplayString(_ctx.titleSubsession) + " ", 1),
      (_ctx.isTitleDropdown)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "icon-subsession",
            name: "chevron-up",
            prefix: "fas"
          }))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: "icon-subsession",
            name: "chevron-down",
            prefix: "fas"
          }))
    ])), [
      [_vShow, _ctx.dropdownButton]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, _ctx.isTitleDropdown]
    ])
  ], 64))
}