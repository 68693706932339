import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__input-number" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "tray-theme-editor__input-number__description" }
const _hoisted_4 = ["id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusSave = _resolveComponent("StatusSave")!
  const _component_StatusSaveInfo = _resolveComponent("StatusSaveInfo")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.tab)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("label", {
          class: "tray-theme-editor__input-number__title",
          for: _ctx.field
        }, _toDisplayString(_ctx.label), 9, _hoisted_2),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1),
        _withDirectives(_createElementVNode("span", { class: "tray-theme-editor__input-number__msg-error" }, _toDisplayString(_ctx.errorMessage), 513), [
          [_vShow, _ctx.errorMessage]
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.field,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        type: "number",
        placeholder: _ctx.placeholder,
        class: "tray-theme-editor__input-number__content"
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.inputValue]
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: ".status-save-template" }, [
      (_ctx.status)
        ? (_openBlock(), _createBlock(_component_StatusSave, {
            key: 0,
            status: "error",
            class: "status-save-position"
          }))
        : _createCommentVNode("", true),
      (_ctx.statusInfo)
        ? (_openBlock(), _createBlock(_component_StatusSaveInfo, {
            key: 1,
            status: "error",
            class: _normalizeClass(["component-status-save-info-validation-input", _ctx.statusInfo ? 'component-status-save-info--active' : ''])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ])),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}