
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'InputFileError',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
});
