import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "unpublished-theme__alert",
  "test-id": "unpublished-theme-alert-limit"
}
const _hoisted_2 = { class: "unpublished-theme__alert-text" }
const _hoisted_3 = { class: "unpublished-theme__alert-options" }
const _hoisted_4 = { class: "unpublished-theme__alert-separator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Icon, {
        class: "unpublished-theme__alert--icon",
        prefix: "fas",
        name: "exclamation-triangle",
        "aria-hidden": "true"
      }),
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.you-have')) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.numberOfThemes) + " " + _toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.old-themes')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.installed-unpublished')) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.msg-alert')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("button", {
        class: "unpublished-theme__alert-text bold",
        "test-id": "see-all-themes",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.seeAllThemes && _ctx.seeAllThemes(...args)))
      }, _toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.see-all-themes')), 513), [
        [_vShow, !_ctx.hiddenSeeAllThemes]
      ]),
      _withDirectives(_createElementVNode("hr", _hoisted_4, null, 512), [
        [_vShow, !_ctx.hiddenSeeAllThemes]
      ]),
      _createElementVNode("button", {
        class: "unpublished-theme__alert-text bold",
        "test-id": "remove-old-themes",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeOldThemes && _ctx.removeOldThemes(...args)))
      }, _toDisplayString(_ctx.$t('components.unpublished-list-themes.alert.remove-themes')), 1)
    ])
  ], 512)), [
    [_vShow, _ctx.showAlertIfThereAreMoreThan50Themes]
  ])
}