enum StorageLocal {
  THEME_CODE = 'tray-theme-editor-opencode-theme',
  THEME_URL = 'tray-theme-editor-theme',
  THEME_STORE_CODE = 'tray-theme-editor-opencode-store',
  THEME_ID = 'tray-theme-editor-theme-id',
  THEME_BASE_URL = 'tray-theme-editor-theme-url',
  THEME_PAGE_SLUG = 'tray-theme-editor-page-slug',
  THEME_PAGE_NAME = 'tray-theme-editor-page-name',
  THEME_NAME = 'tray-theme-editor-theme-name',
  CURRENT_THEME_THEME_URL = 'tray-theme-editor-current-theme-url',
  CURRENT_PATH_URL = 'tray-theme-editor-current-theme-path',
  CURRENT_THEME_CODE = 'tray-theme-editor-current-theme-code',
  TOKEN = 'token',
  CURRENT_USER = 'tray-theme-user-data',
}

export { StorageLocal };
