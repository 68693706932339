import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__input-color" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "tray-theme-editor__input-color__description" }
const _hoisted_4 = { class: "tray-theme-editor__input-color__inputs" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusSave = _resolveComponent("StatusSave")!
  const _component_StatusSaveInfo = _resolveComponent("StatusSaveInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      class: _normalizeClass(_ctx.tab)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", {
          class: "tray-theme-editor__input-color__title",
          for: _ctx.field
        }, _toDisplayString(_ctx.label), 9, _hoisted_2),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            id: _ctx.field,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            type: "color",
            class: "tray-theme-editor__input-color__button"
          }, null, 8, _hoisted_5), [
            [_vModelText, _ctx.inputValue]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
            type: "text",
            placeholder: _ctx.placeholder,
            class: "tray-theme-editor__input-color__text"
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.inputValue]
          ])
        ]),
        _withDirectives(_createElementVNode("span", { class: "tray-theme-editor__input-color__msg-error" }, _toDisplayString(_ctx.errorMessage), 513), [
          [_vShow, _ctx.errorMessage && _ctx.errorMessage.length > 0]
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_openBlock(), _createBlock(_Teleport, { to: ".status-save-template" }, [
      (_ctx.status)
        ? (_openBlock(), _createBlock(_component_StatusSave, {
            key: 0,
            status: "error",
            class: "status-save-position"
          }))
        : _createCommentVNode("", true),
      (_ctx.statusInfo)
        ? (_openBlock(), _createBlock(_component_StatusSaveInfo, {
            key: 1,
            status: "error",
            class: _normalizeClass(["component-status-save-info-validation-input", _ctx.statusInfo ? 'component-status-save-info--active' : ''])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}