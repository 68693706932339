
import { defineComponent } from 'vue';
import { i18n } from '@/plugins/i18n';
export default defineComponent({
  name: 'ChangeLogThemeInfoTheme',
  props: {
    id: {
      type: String,
      default: '-1',
    },
    name: {
      type: String,
      default: i18n.global.t('components.sidebar.changelog.nameless-theme'),
    },
    thumb: {
      type: String,
      default: '',
    },
  },
});
