import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__rename-theme__names" }
const _hoisted_2 = { class: "tray-theme-editor__rename-theme__current-name-theme" }
const _hoisted_3 = { class: "tray-theme-editor__rename-theme__names" }
const _hoisted_4 = ["placeholder", "maxlength"]
const _hoisted_5 = { class: "tray-theme-editor__rename-theme__container-info" }
const _hoisted_6 = { class: "tray-theme-editor__rename-theme__info-validation-char" }
const _hoisted_7 = { class: "tray-theme-editor__rename-theme__counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('components.modals.rename-theme.current-name')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentName), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('components.modals.rename-theme.title-input')), 1),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localName) = $event)),
        placeholder: _ctx.$t('components.modals.rename-theme.title-input'),
        type: "text",
        maxlength: _ctx.limit,
        required: "",
        novalidate: "",
        class: "input-color-gray tray-theme-editor__rename-theme__input-modal"
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.localName]
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("p", {
            "test-id": "rename-theme-error",
            class: "error"
          }, _toDisplayString(_ctx.$t('components.modals.rename-theme.error')), 513), [
            [_vShow, _ctx.error]
          ]),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('components.modals.rename-theme.message-char')), 1)
        ]),
        _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.localName.length) + "/" + _toDisplayString(_ctx.limit), 1)
      ])
    ])
  ]))
}