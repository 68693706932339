import {
  InputCheckboxInterface,
  InputCheckboxAdicionalInterface,
} from './InputCheckbox.interface';

export class InputCheckbox implements InputCheckboxInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public content = '';
  public tab = '';
  public value = false;

  constructor(dto: InputCheckboxInterface) {
    Object.assign(this, dto);
  }
}

export class InputCheckboxAdicional implements InputCheckboxAdicionalInterface {
  public type = '';
  public field = '';
  public content = '';
  public tab = '';
  public value = false;

  constructor(dto: InputCheckboxAdicionalInterface) {
    Object.assign(this, dto);
  }
}
