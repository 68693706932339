import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "unpublished-theme-search-bar",
  style: {"display":"none"}
}
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemeSearchBarFind = _resolveComponent("ThemeSearchBarFind")!
  const _component_ThemeSearchBarClear = _resolveComponent("ThemeSearchBarClear")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      type: "text",
      placeholder: 
        _ctx.$t('components.unpublished-list-themes.search-bar.placeholder')
      ,
      class: "unpublished-theme-search-bar__input"
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.searchValue]
    ]),
    (_ctx.switchBetweenFilterAndSearch)
      ? (_openBlock(), _createBlock(_component_ThemeSearchBarFind, {
          key: 0,
          class: "unpublished-theme-search-bar__buttons-bar-search",
          label: _ctx.$t('components.unpublished-list-themes.search-bar.find')
        }, null, 8, ["label"]))
      : (_openBlock(), _createBlock(_component_ThemeSearchBarClear, {
          key: 1,
          class: "unpublished-theme-search-bar__buttons-bar",
          label: _ctx.$t('components.unpublished-list-themes.search-bar.clear-filter'),
          onClearFilter: _ctx.clearFilter
        }, null, 8, ["label", "onClearFilter"]))
  ]))
}