type UseEnv = {
  /**
   * @name isActiveEnvIs
   * @params [env='live'] String parcial para corresponder ao ambiente atual
   * @description Compara se o ambiente atual é o mesmo do passado por params.
   * @return boolean
   */
  isActiveEnvIs: (env?: string) => boolean;
};

/**
 * @name useEnv
 * @description Função para verificar os tipos ambiente.
 * @return UseEnv
 */
export function useEnv(): UseEnv {
  const isActiveEnvIs = (env = 'live'): boolean =>
    process.env.VUE_APP_CONFIG === env;
  return {
    isActiveEnvIs,
  };
}
