
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    position: {
      type: String,
      default: 'top',
    },
  },
  setup() {
    return;
  },
});
