import { App, AppConfig, Plugin } from 'vue';
import { ENV } from '@/enums';

interface AppConfigCustom extends AppConfig {
  devtools: boolean;
}

export const devtools: Plugin = {
  install(app: App) {
    if (process.env.NODE_ENV !== ENV.DEVELOPMENT) return;
    (app.config as AppConfigCustom).devtools = true;
  },
};
