
import LoadingPage from '@/components/LoadingPage.vue';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
export default defineComponent({
  name: 'PreFetchPage',
  components: {
    LoadingPage,
  },
  mounted() {
    this.login();
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
    }),
  },
});
