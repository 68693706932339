import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__settings-editor__theme" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "tray-theme-editor__settings-editor__menu-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: "tray-theme-editor__section__dropdown-item tray-theme-editor__edit-layout",
    "test-id": "session-btn-icon",
    onMouseover: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isVisibleIcon = true), ["prevent"])),
    onMouseleave: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isVisibleIcon = false), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.iconConfig)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.invalidIcon)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  class: "tray-theme-editor__settings-editor__menu-icon",
                  name: "cog",
                  prefix: "fas",
                  "aria-hidden": "true"
                }))
              : (_openBlock(), _createBlock(_component_Icon, {
                  key: 1,
                  class: "tray-theme-editor__settings-editor__menu-icon",
                  name: _ctx.iconSession,
                  prefix: _ctx.prefixNameIcon,
                  "aria-hidden": "true"
                }, null, 8, ["name", "prefix"]))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.titleSession), 1)
    ]),
    (_ctx.isVisibleIcon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "tray-theme-editor__settings-editor__menu-icon",
          name: "arrow-right",
          prefix: "fas",
          "test-id": "icon-arrow-right"
        }))
      : _createCommentVNode("", true)
  ], 32))
}