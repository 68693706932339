import { InputText } from './InputText.model';

function useInputText(
  label: string,
  type: string,
  description: string[],
  placeholder: string,
  field: string,
  validation: string,
  tab: string,
  icon: string,
  value: string
): InputText {
  return new InputText({
    label,
    type,
    description,
    placeholder,
    field,
    validation,
    tab,
    icon,
    value,
  });
}

export { useInputText };
