import { FieldValidation } from '@/models/composable/use-field-validation';
import { RequiredFieldError } from '@/composable/use-field-validation/errors';

export class RequiredFieldValidation implements FieldValidation {
  constructor(readonly field: string) {}
  validate(input: object): null | Error {
    const keyObject = this.field as keyof object;
    const inputValue = input[keyObject] as string;
    return inputValue ? null : new RequiredFieldError();
  }
}
