
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { Timers } from '@/enums';

interface Notification {
  title: string;
  description: string;
  type: 'success' | 'error' | 'warning';
}

export default defineComponent({
  name: 'Notification',
  components: {
    Icon,
  },
  props: {
    duration: {
      type: Number,
      default: (): number => Timers.AWAIT6000MS,
    },
  },
  data() {
    return {
      title: '',
      description: '',
      typeNotification: 'success',
      show: false,
    };
  },

  computed: {
    typeNotificationClass(): string {
      return `notification__header--${this.typeNotification}`;
    },
    statusNotificationClass(): string {
      return `notification__status-time--${this.typeNotification}`;
    },
  },
  watch: {
    show(isOpen: boolean): void {
      if (isOpen) {
        this.triggerClose();
      }
    },
  },
  methods: {
    async open({
      title,
      description,
      type = 'success',
    }: Notification): Promise<void> {
      await this.triggerAddDurationInStatusBar().then(() => {
        this.title = title;
        this.description = description;
        this.typeNotification = type;
        this.show = true;
      });
    },
    close(): void {
      this.show = false;
      this.title = '';
      this.description = '';
    },
    triggerClose(): void {
      setTimeout(() => {
        this.close();
      }, this.duration);
    },
    async triggerAddDurationInStatusBar(): Promise<number> {
      return setTimeout(() => {
        const statusBarList = document.getElementsByClassName(
          'notification__status-time'
        );
        const statusBar = statusBarList.item(0);
        statusBar?.setAttribute(
          'style',
          `animation-duration: ${this.duration}ms;`
        );
      });
    },
  },
});
