import { Context } from '@/models/store/theme-editor';

export function saving(context: Context, key: string, status: string): void {
  (context.state.saving as any)[key].status = status;
  if (status === 'error' || status === 'saved') {
    setTimeout(() => {
      (context.state.saving as any)[key].status = 'initial';
    }, 5000);
  }
}
