import { ActionsInterface, Mutations } from '@/models/store/user';

const actions: ActionsInterface = {
  login(context): void {
    context.commit(Mutations.SET_STORE_CONFIG);
  },
  recoveryStoreUser(context): void {
    context.commit(Mutations.RECOVERY_USER_STORE_DATA);
  },
};

export default actions;
