import { InputToggleInterface } from './InputToggle.interface';

export class InputToggle implements InputToggleInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public tab = '';
  public value = false;

  constructor(dto: InputToggleInterface) {
    Object.assign(this, dto);
  }
}
