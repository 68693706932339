import { Backup, BackupManager } from '@/patterns/usecase/backup';

export class BackupStorage<T> {
  constructor(
    private backupInstance: Backup<T>,
    private backupManager: BackupManager<T>
  ) {}

  backup(): void {
    this.backupManager.addState();
  }

  undo(): T {
    this.backupManager.undo();
    return this.backupInstance.getData();
  }

  redo(): T {
    this.backupManager.redo();
    return this.backupInstance.getData();
  }
}
