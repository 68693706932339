
import Icon from '@/components/Icon.vue';
import { AlertInterface, useAlert } from './module';
import { defineComponent, PropType } from '@vue/runtime-core';

export default defineComponent({
  name: 'Alert',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    typeAlert: {
      type: String,
      default: 'warning',
    },
    classStyle: {
      type: String,
      default: 'alert',
    },
    inputFields: {
      type: Object as PropType<AlertInterface>,
      required: true,
    },
  },
  setup(props) {
    const { label, type, description, field, link, url } = useAlert(
      props.inputFields.label,
      props.inputFields.type,
      props.inputFields.description,
      props.inputFields.field,
      props.inputFields.link,
      props.inputFields.url
    );
    return {
      label,
      type,
      description,
      field,
      link,
      url,
    };
  },
  computed: {
    alertClasses(): string {
      return this.typeAlert;
    },
  },
});
