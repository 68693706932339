import {
  DataLayerApiClientUrlsInterface,
  DataLayerApiClientInterface,
  DataLayerApiClientModel,
} from '@/models/api-client/data-layer';

import { config } from '@/config';

const urls: DataLayerApiClientUrlsInterface = config.dataLayer.apiUrls;
const DataLayerApiClient: DataLayerApiClientInterface =
  new DataLayerApiClientModel(urls);
export default DataLayerApiClient;
