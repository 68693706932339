
import Icon from '@/components/Icon.vue';
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
  },
  emits: ['load-image-error', 'remove-file', 'edit-file'],
  setup(props, { emit }) {
    const type = ref('');

    const onLoadImageError = (): void => {
      emit('load-image-error');
    };

    const removeFile = (): void => {
      emit('remove-file');
    };

    const editFile = (): void => {
      emit('edit-file');
    };

    const fileType = (): string => {
      const arrayUrl = props.url.split('.');
      const indexType = arrayUrl.length - 1;
      const typeFile = arrayUrl[indexType];

      if (
        typeFile === 'png' ||
        typeFile === 'jpg' ||
        typeFile === 'jpeg' ||
        typeFile === 'webp' ||
        typeFile === 'png' ||
        typeFile === 'gif' ||
        typeFile === 'svg' ||
        typeFile === 'ico'
      ) {
        return (type.value = 'image');
      }

      if (
        typeFile === 'css' ||
        typeFile === 'js' ||
        typeFile === 'json' ||
        typeFile === 'pdf' ||
        typeFile === 'csv' ||
        typeFile === 'woff' ||
        typeFile === 'woff2'
      ) {
        return (type.value = 'document');
      }

      return (type.value = '');
    };

    onMounted(() => {
      fileType();
    });

    onUpdated(() => {
      fileType();
    });

    return {
      onLoadImageError,
      removeFile,
      editFile,
      type,
    };
  },
});
