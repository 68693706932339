import { i18n } from '@/plugins/i18n';

const $t = i18n.global.t;

/**
 * @name defineDate
 * @description Converte a data e hora recebida da api para uma data no formato correto
 * de acordo com a linguagem da loja
 * @param {string} unformattedDate - data e hora vinda da api de Changelog
 * @returns {string} data com dia mês e ano separados por "/" --/--/--
 */
export const convertFormatDate = (unformattedDate: string): any => {
  if (unformattedDate != null) {
    const dateComplete = new Date(unformattedDate);
    const date = dateComplete.toLocaleDateString($t('date-time-format'));
    return date;
  }
  return '';
};
