import { PersistTokenModel } from '@/models/composable';

export class PersistToken implements PersistTokenModel {
  constructor(private readonly prefix: string) {
    this.prefix = prefix;
  }
  save(token: string): void {
    localStorage.setItem(this.prefix, token);
  }
  restore(): string {
    const token = localStorage.getItem(this.prefix);
    if (!token) {
      throw new Error('Token not found');
    }
    return token;
  }
}

export const persistToken = (): PersistToken => {
  return new PersistToken('token');
};
