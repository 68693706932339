
import ThemeItem from './ThemeItem.vue';
import ThemeSearchBar from './ThemeSearchBar.vue';
import ThemeAlertLimit from './ThemeAlertLimit.vue';
import { UnpublishedListThemes } from './module';
import { IthemeDataListingStore } from '@/models/store/theme-editor';
import ThemeDisplayEmpty from '@/components/theme-display-empty/ThemeDisplayEmpty.vue';
import RemoveOldThemesModal from '@/components/modals/remove-theme/RemoveOldThemesModal.vue';
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  components: {
    ThemeItem,
    ThemeSearchBar,
    ThemeAlertLimit,
    ThemeDisplayEmpty,
    RemoveOldThemesModal,
  },
  props: {
    themes: {
      type: Array as PropType<IthemeDataListingStore[]>,
      required: true,
    },
  },
  emits: [
    'theme-edit',
    'theme-html-edit',
    'theme-rename',
    'theme-change-log',
    'theme-duplicate',
    'theme-updated-version',
    'theme-delete',
    'theme-buy',
    'theme-change-payment',
    'theme-publish',
    'theme-preview',
    'theme-show-more-pages',
  ],
  data() {
    return {
      search: '',
      showMoreActive: false,
      componentKey: 0,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      hasMorePages: 'listingHasMorePages',
    }),
    notAllThemesVisible(): boolean {
      return this.themesVisibleOrSearchByNameOrCode.length < this.themes.length;
    },
    showMoreThemesButton(): boolean {
      return (
        this.hasMorePages || (this.notAllThemesVisible && !this.showMoreActive)
      );
    },
    showLessThemesButton(): boolean {
      return (
        this.themesVisibleOrSearchByNameOrCode.length >
          UnpublishedListThemes.VISIBLE_LIMIT &&
        !this.hasMorePages &&
        this.themesVisibleOrSearchByNameOrCode.length === this.themes.length
      );
    },
    themesVisibleOrSearchByNameOrCode(): IthemeDataListingStore[] {
      const value = this.search.toLowerCase();
      if (!value.length) return this.changeLimitVisible();
      return this.themes.filter(this.findByNameOrCode);
    },
    showSearchNoThemes(): boolean {
      return this.themesVisibleOrSearchByNameOrCode.length == 0;
    },
  },
  methods: {
    clearFilter(): void {
      this.search = '';
      this.componentKey += 1;
    },
    showMoreThemes(): void {
      this.showMoreActive = true;
      if (this.hasMorePages == true) {
        return this.themeShowMorePages();
      }
      return this.themes;
    },
    showLessThemes(): void {
      this.showMoreActive = false;
      this.changeLimitVisible();
    },
    findByNameOrCode(theme: IthemeDataListingStore): any {
      if (
        theme.name.toLowerCase().includes(this.search.toLowerCase()) ||
        theme.id.toString().includes(this.search.toLowerCase())
      )
        return theme;
    },
    changeLimitVisible(): IthemeDataListingStore[] {
      if (this.showMoreActive) {
        return this.themes;
      }
      return this.themes.slice(0, UnpublishedListThemes.VISIBLE_LIMIT);
    },
    themeEdit(theme: IthemeDataListingStore): void {
      this.$emit('theme-edit', theme);
    },
    themeHtmlEdit(theme: IthemeDataListingStore): void {
      this.$emit('theme-html-edit', theme);
    },
    themeRename(theme: IthemeDataListingStore): void {
      this.$emit('theme-rename', theme);
    },
    themeChangeLog(theme: IthemeDataListingStore): void {
      this.$emit('theme-change-log', theme);
    },
    themeDuplicate(theme: IthemeDataListingStore): void {
      this.$emit('theme-duplicate', theme);
    },
    themeUpdatedVersion(theme: IthemeDataListingStore): void {
      this.$emit('theme-updated-version', theme);
    },
    themeDelete(theme: IthemeDataListingStore): void {
      this.$emit('theme-delete', theme);
    },
    themeBuy(theme: IthemeDataListingStore): void {
      this.$emit('theme-buy', theme);
    },
    themeChangePayment(theme: IthemeDataListingStore): void {
      this.$emit('theme-change-payment', theme);
    },
    themePublish(theme: IthemeDataListingStore): void {
      this.$emit('theme-publish', theme);
    },
    themePreview(theme: IthemeDataListingStore): void {
      this.$emit('theme-preview', theme);
    },
    themeShowMorePages(): void {
      this.$emit('theme-show-more-pages');
    },
    openModalRemoveOld(): void {
      this.$refs.modalDeleteOldThemes.open();
    },
  },
});
