import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "status-save" }
const _hoisted_2 = {
  key: 0,
  class: "status-save__status status-save__status--saving"
}
const _hoisted_3 = {
  key: 1,
  class: "status-save__status status-save__status--error"
}
const _hoisted_4 = {
  key: 2,
  class: "status-save__status status-save__status--saved"
}
const _hoisted_5 = { class: "status-save-saved" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'saving')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            prefix: "fas",
            name: "circle-notch",
            class: "status-save__icon-saving"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.status-save.saving')), 1)
        ]))
      : (_ctx.status === 'error')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              prefix: "far",
              name: "times-circle",
              class: "status-save__icon-error"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.status-save.error')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Icon, {
              prefix: "far",
              name: "check-circle",
              class: "status-save__icon-saved"
            }),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('components.status-save.saved')), 1)
          ]))
  ]))
}