import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import { ThemeEditorModule } from '@/models/store/theme-editor';

const themeEditor: ThemeEditorModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default themeEditor;
