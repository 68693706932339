import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__custom-resolution-fields" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t('display.width')) + " ", 1),
    _createElementVNode("input", {
      ref: "firstInput",
      type: "number",
      class: "tray-theme-editor__input",
      value: _ctx.convertPxToNumber(_ctx.activeResolution.width),
      "test-id": "input-width",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.changeResolution({
          key: 'width',
          value: _ctx.convertNumberToPx($event.target.value),
        })
      ))
    }, null, 40, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_ctx.$t('display.height')) + " ", 1),
    _createElementVNode("input", {
      type: "number",
      class: "tray-theme-editor__input",
      value: _ctx.convertPxToNumber(_ctx.activeResolution.height),
      "test-id": "input-height",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (
        _ctx.changeResolution({
          key: 'height',
          value: _ctx.convertNumberToPx($event.target.value),
        })
      ))
    }, null, 40, _hoisted_3),
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon",
      name: "undo",
      prefix: "fa",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (
        _ctx.setResolution({
          name: 'custom',
          width: '1000px',
          height: '700px',
          icon: 'arrows-alt-h',
        })
      ))
    })
  ], 512)), [
    [_vShow, _ctx.resolutionIsCustom]
  ])
}