import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCanadianMapleLeaf,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faPinterest,
  faBlogger,
  faWhatsapp,
  faLinkedin,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

const iconsBrand = [
  faCanadianMapleLeaf,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faPinterest,
  faBlogger,
  faWhatsapp,
  faLinkedin,
  faTiktok,
] as IconDefinition[];

export default iconsBrand;
