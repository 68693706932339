import { InputRadio } from './InputRadio.model';
import { OptionInterface } from './InputRadio.interface';

function useInputRadio(
  label: string,
  type: string,
  description: string[],
  field: string,
  options: OptionInterface[],
  tab: string,
  value: string,
  style: boolean
): InputRadio {
  return new InputRadio({
    label,
    type,
    description,
    field,
    options,
    tab,
    value,
    style,
  });
}

export { useInputRadio };
