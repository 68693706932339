import {
  StructureSettingsApiClientInterface,
  StructureSettingsApiClientModel,
  StructureSettingsApiClientUrlsInterface,
} from '@/models/api-client/structure-settings';

import { config } from '@/config';

const urls: StructureSettingsApiClientUrlsInterface =
  config.structureSettings.apiUrls;
const StructureSettingsApiClient: StructureSettingsApiClientInterface =
  new StructureSettingsApiClientModel(urls);
export default StructureSettingsApiClient;
