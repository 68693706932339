
import StatusPublishedTheme from '@/components/list/published/ThemeDisplayStatus.vue';
import ButtonPublishedTheme from '@/components/list/published/ThemeDisplayButton.vue';
import Tooltip from '@/components/core/TooltipDepreciated.vue';
import Icon from '@/components/Icon.vue';
import ThemeTags from '@/components/list/ThemeTags.vue';

import { defineComponent, PropType } from 'vue';
import { IthemeDataListingStore } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'ThemeDisplayTemplate',
  components: {
    StatusPublishedTheme,
    ButtonPublishedTheme,
    Tooltip,
    Icon,
    ThemeTags,
  },
  props: {
    theme: {
      type: Object as PropType<IthemeDataListingStore>,
      required: true,
    },
  },

  emits: [
    'edit-theme-name',
    'update-theme-version',
    'theme-change-log',
    'duplicate-theme',
    'edit-theme',
    'view-store',
    'theme-rename',
  ],

  data() {
    return {
      hoverImage: false,
    };
  },
  methods: {
    updateThemeVersion(): void {
      this.$emit('update-theme-version', this.theme);
    },

    themeChangeLog(): void {
      this.$emit('theme-change-log', this.theme);
    },

    duplicateTheme(): void {
      this.$emit('duplicate-theme', this.theme);
    },

    editTheme(): void {
      this.$emit('edit-theme', this.theme);
    },

    viewStore(): void {
      this.$emit('view-store', this.theme);
    },
    updateName(): void {
      this.$emit('theme-rename', this.theme);
    },
  },
});
