import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unpublished-theme-clear__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: "unpublished-theme-clear",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear-filter')))
  }, [
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon",
      name: "times-circle",
      prefix: "far"
    }),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ]))
}