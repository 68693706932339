
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'InputFileLoading',
  components: {
    Icon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
