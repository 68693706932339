
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  name: 'Subsession',
  components: {
    Icon,
  },
  props: {
    titleSubsession: {
      type: String,
      required: true,
    },
    dropdownButton: {
      type: Boolean,
      default: true,
    },
    dropdown: {
      type: String,
      default: '',
    },
  },
  emits: ['update:dropdown'],
  computed: {
    isTitleDropdown(): boolean {
      return this.dropdown === this.titleSubsession;
    },
  },
  methods: {
    setDropDown(): void {
      const isValue =
        this.dropdown === this.titleSubsession ? '' : this.titleSubsession;
      this.$emit('update:dropdown', isValue);
    },
  },
});
