import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { FileApiClientInterface, FileApiClientUrlsInterface } from './';

export class FileApiClientModel implements FileApiClientInterface {
  constructor(private readonly urls: FileApiClientUrlsInterface) {}

  updateFile(formData: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: `${this.urls.updateFile}`,
      requiresToken: true,
      payload: formData,
    };
    return HttpClient.post(params);
  }
}
