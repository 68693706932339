import { createApp } from 'vue';
import App from './App.vue';
import Icons from './assets/icons';
import { i18n } from './plugins/i18n';
import { devtools } from '@/plugins/devtools';
import Styles from './assets/scss';
import router from './router';
import store from './store';

const app = createApp(App, {});

app
  .use(store)
  .use(Styles)
  .use(Icons)
  .use(i18n)
  .use(router)
  .use(devtools)
  .mount('#tray-theme-editor');

export default app;
