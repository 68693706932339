import { InputTextInterface } from './InputText.interface';

export class InputText implements InputTextInterface {
  public label = '';
  public type = '';
  public description = [];
  public placeholder = '';
  public field = '';
  public validation = '';
  public tab = '';
  public icon = '';
  public value = '';

  constructor(dto: InputTextInterface) {
    Object.assign(this, dto);
  }
}
