
import { mapActions } from 'vuex';
import { defineComponent } from 'vue';
import '../public/gtmAndDataLayer';

export default defineComponent({
  name: 'App',
  beforeMount() {
    this.recoveryStateUser();
  },
  methods: {
    ...mapActions('user', {
      recoveryStateUser: 'recoveryStoreUser',
    }),
  },
});
