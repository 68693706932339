import { InputNumberInterface } from './InputNumber.interface';

export class InputNumber implements InputNumberInterface {
  public label = '';
  public type = '';
  public description = [];
  public placeholder = '';
  public field = '';
  public validation = '';
  public tab = '';
  public value = 0;

  constructor(dto: InputNumberInterface) {
    Object.assign(this, dto);
  }
}
