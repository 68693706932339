import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, renderSlot as _renderSlot, withModifiers as _withModifiers, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "tray-theme-editor__input-textarea__description" }
const _hoisted_3 = { class: "tray-theme-editor__input-textarea__wrapper" }
const _hoisted_4 = ["id", "placeholder"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_StatusSave = _resolveComponent("StatusSave")!
  const _component_StatusSaveInfo = _resolveComponent("StatusSaveInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.tab)
    }, [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("label", {
          class: "tray-theme-editor__input-textarea__title",
          for: _ctx.field
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.label), 1)
        ], 8, _hoisted_1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("textarea", {
            id: _ctx.field,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            class: "tray-theme-editor__input-textarea__input",
            type: "textarea",
            placeholder: _ctx.placeholder,
            name: "text_field",
            rows: "1"
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.inputValue]
          ]),
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                for: _ctx.field
              }, [
                (_ctx.invalidIcon)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      class: _normalizeClass(_ctx.icon),
                      name: "cog",
                      prefix: "fas",
                      "aria-hidden": "true"
                    }, null, 8, ["class"]))
                  : (_openBlock(), _createBlock(_component_Icon, {
                      key: 1,
                      class: _normalizeClass(_ctx.icon),
                      name: _ctx.icon,
                      prefix: _ctx.prefixNameIcon,
                      "aria-hidden": "true"
                    }, null, 8, ["class", "name", "prefix"]))
              ], 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createElementVNode("span", { class: "tray-theme-editor__input-textarea__erro" }, _toDisplayString(_ctx.errorMessage), 513), [
          [_vShow, _ctx.errorMessage && _ctx.errorMessage.length]
        ]),
        _renderSlot(_ctx.$slots, "default")
      ], 32)
    ], 2),
    (_openBlock(), _createBlock(_Teleport, { to: ".status-save-template" }, [
      (_ctx.status)
        ? (_openBlock(), _createBlock(_component_StatusSave, {
            key: 0,
            status: "error",
            class: "status-save-position"
          }))
        : _createCommentVNode("", true),
      (_ctx.statusInfo)
        ? (_openBlock(), _createBlock(_component_StatusSaveInfo, {
            key: 1,
            status: "error",
            class: _normalizeClass(["component-status-save-info-validation-input", _ctx.statusInfo ? 'component-status-save-info--active' : ''])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}