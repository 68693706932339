
import { Timers } from '@/enums';
import InputFileImageError from '../components/InputFileImageError.vue';
import InputFileImageEmpty from '../components/InputFileImageEmpty.vue';
import InputFilePreview from './components/InputFilePreview.vue';
import InputFileImageLoading from '../components/InputFileImageLoading.vue';
import InputFileImageInfo from '../components/InputFileImageInfo.vue';
import { useInputFile, InputFileInterface } from './module';
import { defineComponent, PropType, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

interface Event<T = EventTarget> {
  target: T;
}

export default defineComponent({
  name: 'InputFile',
  components: {
    InputFileImageError,
    InputFileImageEmpty,
    InputFilePreview,
    InputFileImageLoading,
    InputFileImageInfo,
  },
  props: {
    testKey: {
      type: String,
      default: '',
    },
    inputFields: {
      type: Object as PropType<InputFileInterface>,
      required: true,
    },
    urlImage: {
      type: String,
      default: '',
    },
  },
  emits: ['callback', 'update-setting-current', 'update:inputFields'],
  setup(props) {
    const {
      label,
      type,
      description,
      field,
      value,
      validation,
      tab,
      changeFile,
    } = useInputFile(
      props.inputFields.label,
      props.inputFields.type,
      props.inputFields.description,
      props.inputFields.field,
      props.inputFields.value,
      props.inputFields.validation,
      props.inputFields.tab
    );

    const inputTypesAccepted = ref('');
    const fileType = ref('');
    const currentType = ref('');

    const inputValidation = (): string => {
      const validation = props.inputFields.validation
        .split('type:')[1]
        .split('|')[0]
        .split(',');

      return (inputTypesAccepted.value = validation.reduce(
        (previousValue, currentValue, index) => {
          if (index == 1) {
            return `.${previousValue},.${currentValue}`;
          }
          return `${previousValue},.${currentValue}`;
        }
      ));
    };

    inputValidation();

    /**
     * @name isImageFile
     * @description valida os MIME types para verificar se o arquivo é uma imagem
     */
    const isImageFile = (type: string): boolean => {
      currentType.value = type.split('/')[1];
      return currentType.value === 'png' ||
        currentType.value === 'jpg' ||
        currentType.value === 'jpeg' ||
        currentType.value === 'webp' ||
        currentType.value === 'png' ||
        currentType.value === 'gif' ||
        currentType.value === 'svg+xml' ||
        currentType.value === 'vnd.microsoft.icon' ||
        currentType.value === 'x-icon'
        ? true
        : false;
    };

    /**
     * @name isDocumentTypeFile
     * @description valida os MIME types para verificar se o arquivo é um documento
     */
    const isDocumentTypeFile = (type: string): boolean => {
      currentType.value = type.split('/')[1];
      return currentType.value === 'css' ||
        currentType.value === 'javascript' ||
        currentType.value === 'json' ||
        currentType.value === 'pdf' ||
        currentType.value === 'csv' ||
        currentType.value === 'woff' ||
        currentType.value === 'woff2'
        ? true
        : false;
    };

    /**
     * @name setIfFileTypeAccepted
     * @description define se arquivo tem um tipo aceito "image ou document" ou se é
     * invalid
     */
    const setIfFileTypeAccepted = (type: string): string => {
      if (isImageFile(type)) {
        return (fileType.value = 'image');
      }
      if (isDocumentTypeFile(type)) {
        return (fileType.value = 'document');
      }
      return (fileType.value = 'invalid');
    };

    return {
      label,
      type,
      description,
      field,
      value,
      validation,
      tab,
      changeFile,
      setIfFileTypeAccepted,
      fileType,
      inputTypesAccepted,
      currentType,
    };
  },
  data() {
    return {
      urlFile: '',
      message: {
        field: '',
        error: '',
      },
      status: {
        field: '',
        loading: 'initial',
      },
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      fileCurrentUrl: 'fileCurrentUrl',
    }),
    required(): boolean {
      const stringValidation = this.validation.split('|');
      const stringRequiredPosition = stringValidation.slice(0, 1);
      const stringRequired = stringRequiredPosition.toString();
      const requiredValidation = stringRequired.split(':').slice(1);
      if (requiredValidation.toString() == 'true') {
        return true;
      }
      return false;
    },
    classInputError(): string {
      return this.message.field === this.field && this.message.error
        ? 'error_load_image'
        : '';
    },
    isLoading(): boolean {
      return this.status.loading === 'loading';
    },
    isExistImageAndNotLoading(): boolean {
      return this.urlFile && !this.isLoading;
    },
    isErrorToField(): boolean {
      return this.message.field === this.field && this.message.error;
    },
  },
  mounted() {
    this.urlFile = this.value;
    this.message.field = this.field;
    this.status.field = this.field;
    this.loadImageInToData();
  },
  methods: {
    ...mapActions('themeEditor', {
      apiFileUpdate: 'apiFileUpdate',
    }),
    loadImageInToData(): void {
      if (this.urlImage.length) {
        this.urlFile = this.urlImage;
      }
    },
    async callback(event: Event<HTMLInputElement>): Promise<unknown> {
      this.resetError();
      const name = event.target.name;
      const file = event.target.files?.item(0);
      this.setIfFileTypeAccepted(file?.type);
      if (!file) {
        return;
      }

      if (this.fileType === 'invalid') {
        return this.onLoadImageError();
      }

      this.changeStatusLoading('loading');
      const fileObject = this.changeFile(name, file);
      this.urlFile = URL.createObjectURL(file);
      try {
        await this.apiFileUpdate(fileObject);
      } catch {
        this.onLoadImageError();
        this.changeStatusLoading('initial');
      }
      const urlFile = this.fileCurrentUrl;
      this.urlFile = urlFile;

      this.$emit('update-setting-current', {
        key: this.field,
        value: urlFile,
      });
      setTimeout(() => {
        this.changeStatusLoading('initial');
      }, Timers.AWAIT6000MS);
    },
    removeFile(): void {
      this.$emit('update-setting-current', {
        key: this.field,
        value: '',
      });
      this.urlFile = '';
    },
    resetError(): void {
      this.message.error = '';
    },
    handlerError(error: string, reset = false): void {
      this.message.error = error;
      if (!reset) return;
      setTimeout(() => {
        this.resetError();
      }, Timers.AWAIT6000MS);
    },
    changeStatusLoading(loading: string): void {
      this.status.loading = loading;
    },
    onLoadImageError(): void {
      this.handlerError(this.$t('defaults.inputs-file.error.file-load'));
    },
  },
});
