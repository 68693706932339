
import ThemeSearchBarFind from './ThemeSearchBarFind.vue';
import ThemeSearchBarClear from './ThemeSearchBarClear.vue';
import { useLodash } from '@/plugins/lodash';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    ThemeSearchBarFind,
    ThemeSearchBarClear,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { debounce } = useLodash(600);
    return {
      debounce,
    };
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    switchBetweenFilterAndSearch(): boolean {
      return this.searchValue === '';
    },
  },
  watch: {
    searchValue(value: string): void {
      this.updateModelValue(value);
    },
  },
  methods: {
    clearFilter(): void {
      this.searchValue = '';
    },
    updateModelValue(value: string): void {
      this.debounce(() => {
        this.$emit('update:modelValue', value);
      });
    },
  },
});
