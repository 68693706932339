
import ActionsButton from './ThemeActionsButton.vue';
import ButtonOptions from './ThemeButtonOptions.vue';
import PreviewButton from './ThemeButtonPreview.vue';
import ThemeTags from '@/components/list/ThemeTags.vue';
import { PaymentStatus } from '@/enums';

import { computed, defineComponent } from 'vue';
export default defineComponent({
  components: {
    ActionsButton,
    ButtonOptions,
    PreviewButton,
    ThemeTags,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    thumb: {
      type: String,
      required: true,
    },
    paid: {
      type: Boolean,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    updated: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'theme-edit',
    'theme-html-edit',
    'theme-rename',
    'theme-change-log',
    'theme-duplicate',
    'theme-updated-version',
    'theme-delete',
    'theme-buy',
    'theme-change-payment',
    'theme-publish',
    'theme-preview',
  ],
  setup(props) {
    return {
      hasTagPaymentInProcess: computed(
        () => !props.paid && props.payment.status != 'PENDING'
      ),
      PaymentStatus,
    };
  },
});
