
import { defineComponent, PropType, ref } from 'vue';
import { useInputRadio, InputRadioInterface } from './module';
import Icon from '@/components/Icon.vue';
import ZoomImageModal from '@/components/modals/zoom-image/ZoomImageModal.vue';

export default defineComponent({
  name: 'InputRadio',
  components: {
    Icon,
    ZoomImageModal,
  },
  props: {
    inputFields: {
      type: Object as PropType<InputRadioInterface>,
      required: true,
    },
  },
  emits: ['update-setting-current'],
  setup(props) {
    const { label, type, description, field, options, tab, value, style } =
      useInputRadio(
        props.inputFields.label,
        props.inputFields.type,
        props.inputFields.description,
        props.inputFields.field,
        props.inputFields.options,
        props.inputFields.tab,
        props.inputFields.value,
        props.inputFields.style
      );
    const inputValue = ref(value);
    return {
      label,
      type,
      description,
      field,
      options,
      tab,
      value,
      style,
      inputValue,
    };
  },
  data() {
    return {
      urlImage: '',
    };
  },
  watch: {
    inputValue(): void {
      this.eventUpdateInput();
    },
  },
  methods: {
    zoomImage(image: string): void {
      this.urlImage = image;
      this.$refs.modalZoomImage.open(image);
    },
    eventUpdateInput(): void {
      this.$emit('update-setting-current', {
        key: this.field,
        value: this.inputValue,
      });
    },
  },
});
