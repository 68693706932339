
import Modal from '@/components/modals/base-modal/BaseModal.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
  name: 'EditThemeModal',
  components: {
    Modal,
    Alert,
  },
  emits: ['continue'],
  computed: {
    ...mapGetters('themeEditor', {
      themeSelected: 'themeGetSelected',
    }),
  },
  mounted() {
    this.open();
  },
  methods: {
    open(): void {
      if (this.themeSelected.published) this.$refs.modal.open();
    },
    themeEdit(): void {
      this.$emit('continue');
    },
    close(): void {
      this.$refs.modal.close();
    },
  },
});
