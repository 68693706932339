import { InputTab } from './InputTab.model';
import { OptionInterface } from './InputTab.interface';

function useInputTab(
  type: string,
  field: string,
  options: OptionInterface[],
  value: string
): InputTab {
  return new InputTab({
    type,
    field,
    options,
    value,
  });
}

export { useInputTab };
