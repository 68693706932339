import {
  ButtonApiClientUrlsInterface,
  ButtonApiClientInterface,
  ButtonApiClientModel,
} from '@/models/api-client/button';

import { config } from '@/config';

const urls: ButtonApiClientUrlsInterface = config.button.apiUrls;
const ButtonApiClient: ButtonApiClientInterface = new ButtonApiClientModel(
  urls
);
export default ButtonApiClient;
