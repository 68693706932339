import { FieldValidation } from '@/models/composable/use-field-validation';
import { EmailFieldError } from '@/composable/use-field-validation/errors';

export class EmailValidation implements FieldValidation {
  constructor(readonly field: string) {}
  validate(input: object): null | Error {
    const emailRegex =
      /[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?/;
    const keyObject = this.field as keyof object;
    const inputValue = input[keyObject];
    return !inputValue || emailRegex.test(inputValue)
      ? null
      : new EmailFieldError();
  }
}
