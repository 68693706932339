import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Suspense as _Suspense, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tray-theme-editor container-fluid no-gutters" }
const _hoisted_2 = { class: "tray-theme-editor__main col" }
const _hoisted_3 = {
  key: 0,
  class: "tray-theme-editor__label tray-theme-editor__loading"
}
const _hoisted_4 = { class: "editor-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_StatusSaveTemplate = _resolveComponent("StatusSaveTemplate")!
  const _component_ButtonEditor = _resolveComponent("ButtonEditor")!
  const _component_LogoEditor = _resolveComponent("LogoEditor")!
  const _component_SealEditor = _resolveComponent("SealEditor")!
  const _component_SessionsEditor = _resolveComponent("SessionsEditor")!
  const _component_SettingsEditor = _resolveComponent("SettingsEditor")!
  const _component_CustomResolution = _resolveComponent("CustomResolution")!
  const _component_ThemePreview = _resolveComponent("ThemePreview")!
  const _component_LoadingPage = _resolveComponent("LoadingPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("section", {
        class: _normalizeClass(["tray-theme-editor__breadcrumb col-1", _ctx.buttonBackEditor])
      }, [
        _createVNode(_component_Breadcrumb, {
          onCloseSidebar: _cache[0] || (_cache[0] = 
          () => {
            _ctx.closeSidebar();
            _ctx.resetComponentName();
          }
        )
        })
      ], 2),
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_router_view, { onOpen: _ctx.openSidebar }, null, 8, ["onOpen"])
      ]),
      _createVNode(_component_StatusSaveTemplate, {
        "status-saving": _ctx.settingSaving.status
      }, null, 8, ["status-saving"])
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t('ops')), 1))
      : (_openBlock(), _createBlock(_Suspense, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_Transition, {
                name: "slide-sidebar",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_ctx.verifyComponentName('ButtonEditor'))
                    ? (_openBlock(), _createBlock(_component_ButtonEditor, {
                        key: "ButtonEditor",
                        onSetComponent: _ctx.setComponentName,
                        onClose: _ctx.closeSidebar
                      }, null, 8, ["onSetComponent", "onClose"]))
                    : (_ctx.verifyComponentName('LogoEditor'))
                      ? (_openBlock(), _createBlock(_component_LogoEditor, {
                          key: "LogoEditor",
                          onSetComponent: _ctx.setComponentName,
                          onClose: _ctx.closeSidebar
                        }, null, 8, ["onSetComponent", "onClose"]))
                      : (_ctx.verifyComponentName('SealEditor'))
                        ? (_openBlock(), _createBlock(_component_SealEditor, {
                            key: "SealEditor",
                            onSetComponent: _ctx.setComponentName,
                            onClose: _ctx.closeSidebar
                          }, null, 8, ["onSetComponent", "onClose"]))
                        : (_ctx.verifyComponentName('SessionsEditor'))
                          ? (_openBlock(), _createBlock(_component_SessionsEditor, {
                              key: "SessionsEditor",
                              onSetComponent: _ctx.setComponentName,
                              onClose: _ctx.closeSidebar
                            }, null, 8, ["onSetComponent", "onClose"]))
                          : (_ctx.verifyComponentName('SettingsEditor'))
                            ? (_openBlock(), _createBlock(_component_SettingsEditor, {
                                key: "SettingsEditor",
                                onSetComponent: _ctx.setComponentName,
                                onClose: _ctx.closeSidebar
                              }, null, 8, ["onSetComponent", "onClose"]))
                            : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ThemePreview, null, {
                "custom-resolution-pages": _withCtx(() => [
                  _createVNode(_component_CustomResolution)
                ]),
                _: 1
              })
            ])
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_LoadingPage)
          ]),
          _: 1
        }))
  ], 64))
}