
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
