import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "theme-logo-seals" }
const _hoisted_2 = { class: "wrapper-content__header-name" }
const _hoisted_3 = {
  key: 0,
  class: "container container-image"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "container container-image"
}
const _hoisted_6 = {
  key: 2,
  class: "container"
}
const _hoisted_7 = { class: "wrapper-content__msg-logo-seals" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoSealSidebar = _resolveComponent("LogoSealSidebar")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LogoSealSidebar, { ref: "sidebarLogoSeal" }, null, 512),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('components.theme-display.logo-seals.title')), 1),
      (_ctx.activeStoreLogo.url)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "container-image__img",
              src: _ctx.activeStoreLogo.url
            }, null, 8, _hoisted_4)
          ]))
        : (!_ctx.activeStoreLogo.url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Icon, {
                class: "wrapper-content__icon icon-image",
                name: "file-image",
                prefix: "far"
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Icon, {
                class: "wrapper-content__icon",
                name: "file-upload",
                prefix: "fas"
              }),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('components.theme-display.logo-seals.no-logo-seals')), 1)
            ])),
      _createElementVNode("button", {
        class: "wrapper-content__button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSidebarLogoSeal()))
      }, _toDisplayString(_ctx.buttonShowLogo), 1)
    ])
  ], 64))
}