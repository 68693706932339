import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification-over"
}
const _hoisted_2 = { class: "notification" }
const _hoisted_3 = { class: "notification__content" }
const _hoisted_4 = { class: "notification__content--description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(_Transition, { name: "slide-top" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("header", {
                  class: _normalizeClass(["notification__header", _ctx.typeNotificationClass])
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_Icon, {
                    prefix: "fas",
                    name: "times",
                    class: "notification__icon",
                    "aria-hidden": "true",
                    onClick: _ctx.close
                  }, null, 8, ["onClick"])
                ], 2),
                _createElementVNode("hr", {
                  class: _normalizeClass(["notification__status-time notification__status-time--active", _ctx.statusNotificationClass])
                }, null, 2),
                _createElementVNode("main", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}