import { Context } from '@/models/store/theme-editor';

export function loading(context: Context, key: string, status: string): void {
  (context.state.loading as any)[key].status = status;
  if (status === 'error') {
    setTimeout(() => {
      (context.state.loading as any)[key].status = 'initial';
    }, 5000);
  }
}
