
import ThemeDisplayEmptyButton from '@/components/theme-display-empty/ThemeDisplayEmptyButton.vue';
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThemeDisplayEmpty',
  components: {
    ThemeDisplayEmptyButton,
    Icon,
  },
  props: {
    urlThemeStore: {
      type: String,
      default: '/',
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    buttonThemeStore: {
      type: Boolean,
      required: true,
    },
    listUnpublished: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['clear-filter'],
});
