import { InputNumber } from './InputNumber.model';

function useInputNumber(
  label: string,
  type: string,
  description: string[],
  placeholder: string,
  field: string,
  validation: string,
  tab: string,
  value: number
): InputNumber {
  return new InputNumber({
    label,
    type,
    description,
    placeholder,
    field,
    validation,
    tab,
    value,
  });
}

export { useInputNumber };
