import { InputRadioInterface, OptionInterface } from './InputRadio.interface';

export class InputRadio implements InputRadioInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public options = [] as OptionInterface[];
  public tab = '';
  public value = '';
  public style = false;

  constructor(dto: InputRadioInterface) {
    Object.assign(this, dto);
  }
}
