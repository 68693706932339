import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input-select" }
const _hoisted_2 = { class: "input-select__title" }
const _hoisted_3 = { class: "input-select__description" }
const _hoisted_4 = { class: "select" }
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  hidden: "",
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.tab)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("article", null, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("select", {
          id: _ctx.field,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
        }, [
          _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t('defaults.inputs-select.select')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: option.value
            }, _toDisplayString(option.content), 9, _hoisted_7))
          }), 128))
        ], 8, _hoisted_5), [
          [_vModelSelect, _ctx.inputValue]
        ])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}