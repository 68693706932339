
import { InputTabInterface, useInputTab } from './module';
import { defineComponent, PropType, ref, onMounted, watch } from 'vue';

export default defineComponent({
  name: 'InputTab',
  props: {
    inputFields: {
      type: Object as PropType<InputTabInterface>,
      required: true,
    },
  },
  setup(props) {
    const { options } = useInputTab(
      props.inputFields.type,
      props.inputFields.field,
      props.inputFields.options,
      props.inputFields.value
    );
    const tabActive = ref<string>('');
    const tabs = ref<string[]>([]);

    function onClick(tab: string): void {
      tabActive.value = tab;
    }
    watch(
      () => tabActive.value,
      () => {
        tabs.value.forEach((tab) => {
          const elementsTab = ref(document.getElementsByClassName(tab));

          for (
            let iterator = 0;
            iterator < elementsTab.value.length;
            iterator++
          ) {
            const element = elementsTab.value[iterator];
            element.setAttribute('style', 'display:none;');
          }
          if (tab === tabActive.value) {
            for (let i = 0; i < elementsTab.value.length; i++) {
              elementsTab.value[i].removeAttribute('style');
            }
          }
        });
      }
    );

    onMounted(() => {
      tabs.value = options.map((option) => option.value);
      tabActive.value = tabs.value[0];
    });

    return {
      onClick,
      tabActive,
    };
  },
});
