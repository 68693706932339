
import { defineComponent, onDeactivated, ref } from 'vue';
import Icon from '@/components/Icon.vue';
import iconsBrand from '@/assets/icons/brands';
import iconsRegular from '@/assets/icons/regular';
import iconsSolid from '@/assets/icons/solid';

export default defineComponent({
  name: 'Session',
  components: {
    Icon,
  },
  props: {
    titleSession: {
      type: String,
      default: 'titleSession',
    },
    routerSession: {
      type: String,
      default: 'settings',
    },
    id: {
      type: Number,
      default: 0,
    },
    routerConfig: {
      type: Boolean,
      default: true,
    },
    iconConfig: {
      type: Boolean,
      default: true,
    },
    iconSession: {
      type: String,
      default: 'cog',
    },
  },
  setup() {
    const isVisibleIcon = ref(false);
    onDeactivated(() => {
      isVisibleIcon.value = false;
    });
    return {
      isVisibleIcon,
    };
  },
  computed: {
    invalidIcon(): boolean {
      const iconSessions = this.iconSession;
      const brand = Object.values(iconsBrand).find(function (icon) {
        return icon.iconName === iconSessions;
      });
      const regular = Object.values(iconsRegular).find(function (icon) {
        return icon.iconName === iconSessions;
      });
      const solid = Object.values(iconsSolid).find(function (icon) {
        return icon.iconName === iconSessions;
      });
      return (
        this.iconSession != brand?.iconName &&
        this.iconSession != regular?.iconName &&
        this.iconSession != solid?.iconName
      );
    },
    prefixNameIcon(): string {
      const iconSessions = this.iconSession;
      const brand = Object.values(iconsBrand).find(function (icon) {
        return icon.iconName === iconSessions;
      });
      const regular = Object.values(iconsRegular).find(function (icon) {
        return icon.iconName === iconSessions;
      });
      if (iconSessions === brand?.iconName) return 'fab';
      if (iconSessions === regular?.iconName) return 'far';
      return 'fas';
    },
  },
});
