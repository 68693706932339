import _ from 'lodash';
import { reactive } from 'vue';

const state = reactive({
  TIME_WAIT_SYNC: 1500,
  TIME_WAIT: 1000,
});

export const debounce = _.debounce((fn: Function): void => {
  fn();
}, state.TIME_WAIT);

export const cloneDeep = _.cloneDeep;

export function useLodash(wait = 1000): {
  debounce: (fn: Function) => void;
  debounced: typeof _.debounce;
  cloneDeep: (obj: any) => any;
} {
  const debounce = _.debounce((fn: Function): void => {
    fn();
  }, wait);

  const cloneDeep = _.cloneDeep;
  const debounced = _.debounce;

  return {
    debounce,
    debounced,
    cloneDeep,
  };
}
