
import { defineComponent, PropType, ref, onMounted } from 'vue';
import { useInputText, InputTextInterface } from './module';
import StatusSave from '@/components/status-theme-save/StatusSave.vue';
import StatusSaveInfo from '@/components/status-theme-save/StatusSaveInfo.vue';
import Icon from '@/components/Icon.vue';
import iconsBrand from '@/assets/icons/brands';
import iconsRegular from '@/assets/icons/regular';
import iconsSolid from '@/assets/icons/solid';
import { useValidate } from '@/components/fields/validations';

export default defineComponent({
  name: 'InputText',
  components: {
    StatusSave,
    StatusSaveInfo,
    Icon,
  },
  props: {
    inputFields: {
      type: Object as PropType<InputTextInterface>,
      required: true,
    },
  },
  emits: ['update-setting-current'],
  setup(props) {
    const {
      label,
      type,
      description,
      placeholder,
      field,
      validation,
      tab,
      icon,
      value,
    } = useInputText(
      props.inputFields.label,
      props.inputFields.type,
      props.inputFields.description,
      props.inputFields.placeholder,
      props.inputFields.field,
      props.inputFields.validation,
      props.inputFields.tab,
      props.inputFields.icon,
      props.inputFields.value
    );
    const inputValue = ref(value);
    const validate = useValidate({
      field: field,
      rules: validation,
    });
    onMounted(() => {
      validate.createValidation();
    });
    return {
      label,
      type,
      description,
      placeholder,
      field,
      validation,
      icon,
      tab,
      value,
      inputValue,
      validate,
    };
  },
  data() {
    return {
      status: false,
      statusInfo: false,
      errorMessage: '',
    };
  },
  computed: {
    prefixNameIcon(): string {
      const iconInputText = this.icon;
      const brand = Object.values(iconsBrand).find(function (icon) {
        return icon.iconName === iconInputText;
      });
      const regular = Object.values(iconsRegular).find(function (icon) {
        return icon.iconName === iconInputText;
      });
      if (this.icon === brand?.iconName) return 'fab';
      if (this.icon === regular?.iconName) return 'far';
      return 'fas';
    },
    invalidIcon(): boolean {
      const iconInputText = this.icon;
      const brand = Object.values(iconsBrand).find(function (icon) {
        return icon.iconName === iconInputText;
      });
      const regular = Object.values(iconsRegular).find(function (icon) {
        return icon.iconName === iconInputText;
      });
      const solid = Object.values(iconsSolid).find(function (icon) {
        return icon.iconName === iconInputText;
      });
      return (
        this.icon != brand?.iconName &&
        this.icon != regular?.iconName &&
        this.icon != solid?.iconName
      );
    },
  },
  watch: {
    inputValue(value): void {
      this.emitInputData(value);
    },
  },
  methods: {
    createErrorMessage(message: string | null): void {
      this.errorMessage = message;
    },
    emitInputData(value: string): void {
      const isError = this.validate.validation(value);
      this.createErrorMessage(isError);
      if (isError) {
        this.status = true;
        this.statusInfo = true;
        return;
      }
      this.status = false;
      this.statusInfo = false;
      this.$emit('update-setting-current', {
        key: this.field,
        value: value,
      });
    },
  },
});
