import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { ButtonApiClientUrlsInterface } from './ButtonApiClientUrls.interface';
import { ButtonApiClientInterface } from './ButtonApiClient.interface';
export class ButtonApiClientModel implements ButtonApiClientInterface {
  private readonly urls!: ButtonApiClientUrlsInterface;

  constructor(urls: ButtonApiClientUrlsInterface) {
    this.urls = urls;
  }

  updateButton(button: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.updateButton,
      requiresToken: false,
      payload: button,
    };

    return HttpClient.post(params);
  }
}
