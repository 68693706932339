
import { defineComponent } from 'vue';
import Tooltip from '@/components/core/TooltipDepreciated.vue';

export default defineComponent({
  name: 'BaseModalFooter',
  components: {
    Tooltip,
  },
  props: {
    labelButton: {
      type: String,
      required: true,
    },
    typeButton: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'continue', 'save', 'cancel'],
  computed: {
    hideTooltip(): boolean {
      return this.isDisabled ? true : false;
    },
  },
});
