
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ThemeInfo from '@/components/ThemeInfo.vue';

export default defineComponent({
  name: 'StoreInfo',
  components: {
    ThemeInfo,
  },
  computed: {
    ...mapGetters('themeEditor', {
      theme: 'themeGetSelected',
    }),
  },
});
