import {
  FileApiClientUrlsInterface,
  FileApiClientInterface,
  FileApiClientModel,
} from '@/models/api-client/file';

import { config } from '@/config';

const urls: FileApiClientUrlsInterface = config.files.apiUrls;
const FileApiClient: FileApiClientInterface = new FileApiClientModel(urls);
export default FileApiClient;
