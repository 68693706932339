
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { useInputNumber, InputNumberInterface } from './module';
import StatusSave from '@/components/status-theme-save/StatusSave.vue';
import StatusSaveInfo from '@/components/status-theme-save/StatusSaveInfo.vue';
import { useValidate } from '@/components/fields/validations';

export default defineComponent({
  name: 'InputNumber',
  components: {
    StatusSave,
    StatusSaveInfo,
  },
  props: {
    inputFields: {
      type: Object as PropType<InputNumberInterface>,
      required: true,
    },
  },
  emits: ['update-setting-current'],
  setup(props) {
    const {
      label,
      type,
      description,
      placeholder,
      field,
      validation,
      tab,
      value,
    } = useInputNumber(
      props.inputFields.label,
      props.inputFields.type,
      props.inputFields.description,
      props.inputFields.placeholder,
      props.inputFields.field,
      props.inputFields.validation,
      props.inputFields.tab,
      props.inputFields.value
    );
    const inputValue = ref(value);
    const validate = useValidate({
      field: field,
      rules: 'required:true|min:1|max:10',
    });
    onMounted(() => {
      validate.createValidation();
    });
    return {
      label,
      type,
      description,
      placeholder,
      field,
      validation,
      tab,
      value,
      inputValue,
      validate,
    };
  },
  data() {
    return {
      status: false,
      statusInfo: false,
      errorMessage: '',
    };
  },
  watch: {
    inputValue(value): void {
      this.emitInputData(value);
    },
  },
  methods: {
    createErrorMessage(message: string | null): void {
      this.errorMessage = message;
    },
    emitInputData(value: number): void {
      const isError = this.validate.validation(value);
      this.createErrorMessage(isError);
      if (isError) {
        this.status = true;
        this.statusInfo = true;
        return;
      }
      this.status = false;
      this.statusInfo = false;
      this.$emit('update-setting-current', {
        key: this.field,
        value: value,
      });
    },
  },
});
