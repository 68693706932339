import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-overlay"
}
const _hoisted_2 = { class: "modal__header" }
const _hoisted_3 = { class: "modal__title" }
const _hoisted_4 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BaseModalFooter = _resolveComponent("BaseModalFooter")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(_Transition, {
      name: "fade",
      type: "transition"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
              _createElementVNode("section", {
                class: _normalizeClass(["modal", _ctx.classStyle])
              }, [
                _createElementVNode("header", _hoisted_2, [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_Icon, {
                    class: "modal__icon",
                    name: "times-circle",
                    "aria-hidden": "true",
                    onClick: _ctx.close
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("main", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _createVNode(_component_BaseModalFooter, {
                  "label-button": _ctx.labelButton,
                  "type-button": _ctx.typeButton,
                  info: _ctx.info,
                  "is-disabled": _ctx.isDisabled,
                  onDelete: _ctx.actions,
                  onContinue: _ctx.actions,
                  onSave: _ctx.actions,
                  onCancel: _ctx.close
                }, null, 8, ["label-button", "type-button", "info", "is-disabled", "onDelete", "onContinue", "onSave", "onCancel"])
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}