import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "changelog-theme__content" }
const _hoisted_2 = { class: "changelog-theme__content__header" }
const _hoisted_3 = { class: "changelog-theme__content__header-title" }
const _hoisted_4 = { class: "changelog-theme__content__header-user" }
const _hoisted_5 = { class: "changelog-theme__content__header-action" }
const _hoisted_6 = { class: "changelog-theme__content__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeLogItem = _resolveComponent("ChangeLogItem")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('components.sidebar.changelog.list.header.date')), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('components.sidebar.changelog.list.header.username')), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('components.sidebar.changelog.list.header.changes')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changelog, (change, index) => {
        return (_openBlock(), _createBlock(_component_ChangeLogItem, _mergeProps({ key: index }, change), null, 16))
      }), 128))
    ])
  ]))
}