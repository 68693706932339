import {
  FieldValidation,
  Validation,
} from '@/models/composable/use-field-validation';

export class ValidationComposite implements Validation {
  private constructor(private readonly validations: FieldValidation[]) {}
  static build(validators: FieldValidation[]): ValidationComposite {
    return new ValidationComposite(validators);
  }
  validate(fieldName: string, input: object): string | null {
    const validators = this.validations.filter((v) => v.field === fieldName);
    let errorMessage = null;
    for (const validator of validators) {
      const error = validator.validate(input);
      if (error) {
        errorMessage = error.message;
      }
    }
    return errorMessage;
  }
}
