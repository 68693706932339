
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
import { Timers } from '@/enums';
export default defineComponent({
  name: 'BaseSideBar',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isTeleported: false,
      isOpen: false,
    };
  },
  methods: {
    open(): void {
      this.isTeleported = true;
      setTimeout(() => {
        this.isOpen = true;
      }, Timers.AWAIT200MS);
    },
    close(): void {
      this.isOpen = false;
      setTimeout(() => {
        this.isTeleported = false;
        this.$emit('close');
      }, Timers.AWAIT200MS);
    },
  },
});
