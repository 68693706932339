import { State } from '@/models/store/user';
import { config } from '@/config';

const themeEditor = (): State => ({
  user: {
    username: '',
  },
  store: {
    code: '',
    urlPreview: '',
    urlStore: '',
  },
  tokenParams: {
    themeCode: '',
    themeId: '',
  },
  urls: {
    storeThemes: config.global.themeStore,
    panel: '',
    panelListThemes: '',
  },
  isLogged: false,
});

export default themeEditor;
