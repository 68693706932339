import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["alert", `${_ctx.classStyle} alert-${_ctx.alertClasses}`])
  }, [
    _withDirectives(_createVNode(_component_Icon, {
      class: "alert__icon",
      prefix: "fas",
      name: "exclamation-triangle",
      "aria-hidden": "true"
    }, null, 512), [
      [_vShow, _ctx.icon]
    ]),
    _createElementVNode("div", {
      id: _ctx.field,
      class: "alert__text"
    }, [
      _createElementVNode("h3", {
        class: _normalizeClass(["alert__text--title", `alert__text--title-${_ctx.alertClasses}`])
      }, _toDisplayString(_ctx.label), 3),
      _createElementVNode("span", {
        class: _normalizeClass(["alert__text--description", `alert__text--description-${_ctx.alertClasses}`])
      }, [
        _createTextVNode(_toDisplayString(_ctx.description) + " ", 1),
        _createElementVNode("a", {
          class: "alert__text--link",
          href: _ctx.url,
          target: "_blank"
        }, _toDisplayString(_ctx.link), 9, _hoisted_2)
      ], 2)
    ], 8, _hoisted_1)
  ], 2))
}