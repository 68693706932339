import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export const noBackThemeListIfProd = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  isNavigate: boolean
): any => {
  if (to.name === 'ThemeListPage' && isNavigate) {
    next({ name: 'Home' });
  } else next();
};
