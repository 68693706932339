import { InputTextarea } from './InputTextarea.model';

function useInputTextArea(
  label: string,
  type: string,
  description: string[],
  placeholder: string,
  field: string,
  validation: string,
  tab: string,
  icon: string,
  value: string
): InputTextarea {
  return new InputTextarea({
    label,
    type,
    description,
    placeholder,
    field,
    validation,
    tab,
    icon,
    value,
  });
}

export { useInputTextArea };
