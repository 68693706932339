import { IthemeDataApi } from './index';

export class ThemeListingData implements IthemeDataApi {
  public id = 1;
  public app_theme = 1234;
  public entity_id = 0;
  public name = '';
  public code = '';
  public preview = '';
  public published = 0;
  public has_structure = false;
  public thumb = '';
  public paid = false;
  public payment?: { link: ''; status: '' };
  public created_at = '';
  public updated = true;
  public updated_at = '';
  public version = '';

  constructor(dto: IthemeDataApi) {
    Object.assign(this, dto);
  }
}
