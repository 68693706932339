import { Alert } from './Alert.model';

function useAlert(
  label: string,
  type: string,
  description: string[],
  field: string,
  link: string,
  url: string
): Alert {
  return new Alert({
    label,
    type,
    description,
    field,
    link,
    url,
  });
}

export { useAlert };
