
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (value: string): boolean {
        return (
          ['buy', 'publish', 'change-payment', 'delete'].indexOf(value) !== -1
        );
      },
    },
  },
  emits: ['theme-buy', 'theme-publish', 'theme-change-payment', 'theme-delete'],
  computed: {
    addStyleInButton(): string {
      return this.type === 'buy'
        ? 'button--green'
        : this.type === 'delete'
        ? 'button--red button--outlined-red'
        : 'button--blue button--outlined';
    },
  },
  methods: {
    emitEvent(): void {
      this.type === 'buy'
        ? this.$emit('theme-buy')
        : this.type === 'change-payment'
        ? this.$emit('theme-change-payment')
        : this.type === 'delete'
        ? this.$emit('theme-delete')
        : this.$emit('theme-publish');
    },
  },
});
