import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["srcdoc", "height", "width"]
const _hoisted_2 = ["srcdoc", "height", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("iframe", {
      class: "tray-theme-editor__iframe",
      srcdoc: _ctx.templatePageHome,
      title: "iframe da página home",
      sandbox: "allow-scripts allow-same-origin allow-forms",
      seamless: "",
      crossorigin: "crossorigin",
      height: _ctx.activeResolution.height,
      width: _ctx.activeResolution.width
    }, null, 8, _hoisted_1), [
      [_vShow, _ctx.$route.name === 'Home']
    ]),
    _withDirectives(_createElementVNode("iframe", {
      ref: "templatePagePreview",
      class: "tray-theme-editor__iframe",
      srcdoc: _ctx.templatePagePreview,
      title: "iframe da página preview",
      seamless: "",
      crossorigin: "crossorigin",
      height: _ctx.activeResolution.height,
      width: _ctx.activeResolution.width,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadIframe && _ctx.loadIframe(...args)))
    }, null, 40, _hoisted_2), [
      [_vShow, _ctx.$route.name === 'Pré visualização']
    ])
  ], 64))
}