import { defaultValues } from './../config';
import { State, IthemeDataListingStore } from '@/models/store/theme-editor';
import { CustomPageInterface } from '@/models/custom-page/CustomPage.interface';
import {
  Current,
  Info,
  Setting,
} from '@/models/structure-settings/StructureSettings.interface';
import { ImageInterface } from '@/models/api-client/image';
import { IchangeLog } from '@/models/change-log';
import { IgetByIdThemeApi } from '@/models/editor';

const themeEditor = (): State => ({
  page: {
    list: [] as CustomPageInterface[],
    defaultList: defaultValues.pages.list,
    active: defaultValues.pages.list[0],
    updated: false,
  },
  theme: {
    sort: 'desc',
    list: [] as IthemeDataListingStore[],
    selected: {} as IgetByIdThemeApi | IthemeDataListingStore,
    published: {} as IthemeDataListingStore,
    info: {} as Info,
    updated: false,
    hasMorePages: false,
  },
  preview: {
    url: null,
    template: '',
    resolution: defaultValues.resolutions.list[0],
    resolutions: defaultValues.resolutions.list,
  },
  file: {
    currentUrl: '',
  },
  image: {
    list: [] as ImageInterface[],
    logo: defaultValues.images.logo,
    seal: defaultValues.images.seal,
    button: defaultValues.images.button,
    updated: false,
  },
  setting: {
    current: {} as Current,
    active: {} as Setting,
    list: [] as Setting[],
    updated: false,
  },
  changeLog: {
    list: [] as IchangeLog[],
  },
  loading: {
    initialLoading: {
      status: 'initial',
      component: 'theme-list-page',
      key: 'initial',
    },
    preview: {
      status: 'initial',
      component: 'initial',
      key: 'preview',
    },
    page: {
      status: 'initial',
      component: 'initial',
      key: 'setting',
    },
    theme: {
      status: 'initial',
      component: 'initial',
      key: 'setting',
    },
    image: {
      status: 'initial',
      component: 'initial',
      key: 'setting',
    },
    setting: {
      status: 'initial',
      component: 'initial',
      key: 'setting',
    },
  },
  saving: {
    image: {
      status: 'initial',
      key: 'image',
    },
    setting: {
      status: 'initial',
      key: 'setting',
    },
  },
});

export default themeEditor;
