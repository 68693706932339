
import Icon from '@/components/Icon.vue';
import Session from '@/components/sessions/Session.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { mapGetters, mapMutations } from 'vuex';
import { Setting } from '@/models/structure-settings/StructureSettings.interface';
import { defineComponent } from 'vue';
import { Mutations } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'SettingsEditor',
  components: {
    Icon,
    Session,
    Alert,
  },
  emits: ['close', 'set-component'],
  computed: {
    ...mapGetters('themeEditor', {
      settingList: 'settingList',
      settingLoading: 'settingLoading',
    }),
  },
  methods: {
    ...mapMutations('themeEditor', {
      setSession: Mutations.SETTINGS_SET_ACTIVE_SESSION,
    }),
    verifyLoading(status: string): boolean {
      return this.settingLoading.status === status;
    },
    selectSession(session: Setting): void {
      this.setSession(session);
      this.$emit('set-component', 'SessionsEditor');
    },
  },
});
