import { BackupCommand } from './BackupCommand';

export class BackupApp {
  private commands: { [key: string]: BackupCommand } = {};

  addCommand(key: string, command: BackupCommand): void {
    this.commands[key] = command;
  }

  executeCommand(key: string): void {
    this.commands[key].execute();
  }

  undoCommand(key: string): void {
    this.commands[key].undo();
  }
}
