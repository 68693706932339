
import Icon from '@/components/Icon.vue';
import Modal from '@/components/modals/base-modal/BaseModal.vue';
import { IthemeDataListingStore } from '@/models/store/theme-editor';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: 'RemoveThemeModal',
  components: {
    Modal,
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: Object as PropType<IthemeDataListingStore>,
      required: true,
    },
  },
  emits: ['delete'],
  methods: {
    themeDelete(isRemove: boolean): void {
      this.$emit('delete', isRemove);
    },
    open(): void {
      this.$refs.modal.open();
    },
  },
});
