import { FieldValidation } from '@/models/composable/use-field-validation';
import { MinFieldError } from '@/composable/use-field-validation/errors';

export class MinLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly min: number) {}
  validate(input: object): null | Error {
    const keyObject = this.field as keyof object;
    const inputValue = input[keyObject] as number | string;
    if (typeof inputValue === 'number') {
      return inputValue < this.min ? new MinFieldError(this.min) : null;
    }
    return inputValue.length < this.min ? new MinFieldError(this.min) : null;
  }
}
