
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RenameTheme',
  props: {
    currentName: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: ['update:name'],
  data() {
    return {
      error: false,
      localName: '',
      limit: 100,
    };
  },
  watch: {
    localName(name): void {
      this.eventUpdateName(name);
    },
  },
  methods: {
    eventUpdateName(name: string): void {
      this.error = false;
      if (!name) {
        this.error = true;
        return;
      }
      this.$emit('update:name', name);
    },
  },
});
