import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "changelog-theme__content__item" }
const _hoisted_2 = { class: "changelog-theme__content__item-date" }
const _hoisted_3 = { class: "changelog-theme__content__item-date-hour" }
const _hoisted_4 = { class: "changelog-theme__content__item__user" }
const _hoisted_5 = { class: "changelog-theme__content__item__user-name" }
const _hoisted_6 = { class: "changelog-theme__content__item-info" }
const _hoisted_7 = { class: "changelog-theme__content__item-info-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.date), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.hour), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createVNode(_component_Tooltip, {
        class: "changelog-theme__tooltip changelog-theme__tooltip--name",
        position: "bottom",
        theme: "dark",
        label: _ctx.username
      }, null, 8, ["label"]), [
        [_vShow, _ctx.showTooltipBasedOnUserNameSize]
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.username), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.description), 1),
      _withDirectives(_createVNode(_component_Tooltip, {
        class: "changelog-theme__tooltip",
        position: "bottom",
        theme: "dark",
        label: _ctx.description
      }, null, 8, ["label"]), [
        [_vShow, _ctx.showTooltipBasedOnDescriptionSize]
      ])
    ])
  ]))
}