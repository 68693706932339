
import SideBar from '@/components/sidebar/BaseSideBar.vue';
import ChangeLogList from '@/components/sidebar/changelog-theme/ChangeLogList.vue';
import ChangeLogThemeInfo from '@/components/sidebar/changelog-theme/ChangeLogInfoTheme.vue';
import ChangeLogListEmpty from '@/components/sidebar/changelog-theme/ChangeLogListEmpty.vue';
import { defineComponent, PropType } from 'vue';
import { IchangeLog } from '@/models/change-log';
import { IthemeDataListingStore } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'ChangeLog',
  components: {
    SideBar,
    ChangeLogThemeInfo,
    ChangeLogList,
    ChangeLogListEmpty,
  },
  props: {
    theme: {
      type: Object as PropType<IthemeDataListingStore>,
      required: true,
    },
    changelog: {
      type: Object as PropType<IchangeLog[]>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    hasChanges(): boolean {
      return this.changelog.length > 0;
    },
  },
  methods: {
    open(): void {
      this.$refs.sidebar.open();
    },
    close(): void {
      this.$emit('close');
    },
  },
});
