import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sidebar-overlay" }
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "sidebar__header" }
const _hoisted_4 = { class: "sidebar__header--title" }
const _hoisted_5 = { class: "sidebar__content" }
const _hoisted_6 = { class: "sidebar__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#sidebar" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_Transition, { name: "slide-fade" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("section", _hoisted_2, [
                _createElementVNode("header", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_Icon, {
                    prefix: "far",
                    name: "times-circle",
                    class: "sidebar__header--icon",
                    "aria-hidden": "true",
                    onClick: _ctx.close
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("footer", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "sidebar__button sidebar__button--close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                  }, _toDisplayString(_ctx.$t('components.sidebar.button.close')), 1)
                ])
              ], 512), [
                [_vShow, _ctx.isOpen]
              ])
            ]),
            _: 3
          })
        ], 512), [
          [_vShow, _ctx.isTeleported]
        ])
      ]),
      _: 3
    })
  ]))
}