import { InputToggle } from './InputToggle.model';

function useInputToggle(
  label: string,
  type: string,
  description: string[],
  field: string,
  tab: string,
  value: boolean
): InputToggle {
  return new InputToggle({
    label,
    type,
    description,
    field,
    tab,
    value,
  });
}

export { useInputToggle };
