import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RenameTheme = _resolveComponent("RenameTheme")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseModal, {
      ref: "modal",
      title: _ctx.$t('components.modals.modal-rename-theme.edit-name-theme'),
      "label-button": _ctx.$t('components.modals.modal-rename-theme.button-save'),
      info: _ctx.$t('components.modals.modal-rename-theme.tooltip-info'),
      "type-button": "save",
      "is-disabled": _ctx.isDisableBtnSave,
      onSave: _ctx.save
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_RenameTheme, {
            name: _ctx.name,
            "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            "current-name": _ctx.selectedTheme.name
          }, null, 8, ["name", "current-name"])
        ])
      ]),
      _: 1
    }, 8, ["title", "label-button", "info", "is-disabled", "onSave"]),
    _createElementVNode("div", null, [
      _createVNode(_component_Notification, { ref: "notification" }, null, 512)
    ])
  ], 64))
}