import {
  SealApiClientUrlsInterface,
  SealApiClientInterface,
  SealApiClientModel,
} from '@/models/api-client/seal';

import { config } from '@/config';

const urls: SealApiClientUrlsInterface = config.seal.apiUrls;
const SealApiClient: SealApiClientInterface = new SealApiClientModel(urls);
export default SealApiClient;
