import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input-tab__margin-bottom" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputFields.options, (input, index) => {
      return (_openBlock(), _createElementBlock("button", {
        key: index,
        class: _normalizeClass(["input-tab__btn", {
          'input-tab__btn-active': input.value === _ctx.tabActive,
        }]),
        onClick: _withModifiers(($event: any) => (_ctx.onClick(input.value)), ["prevent"])
      }, _toDisplayString(input.content), 11, _hoisted_2))
    }), 128))
  ]))
}