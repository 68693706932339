
import Modal from '@/components/modals/base-modal/BaseModal.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { IthemeDataListingStore } from '@/models/store/theme-editor';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BuyThemeModal',
  components: {
    Modal,
    Alert,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: Object as PropType<IthemeDataListingStore>,
      required: true,
    },
  },
  emits: ['theme-buy'],
  data() {
    return {
      checkbox: false,
      isDisabled: true,
    };
  },
  watch: {
    checkbox(isChecked): void {
      this.isDisabled = true;
      if (!isChecked) return;
      this.isDisabled = false;
    },
  },
  methods: {
    themeBuy(): void {
      this.$emit('theme-buy');
      this.resetCheckBox();
    },
    resetCheckBox(): void {
      this.checkbox = false;
    },
    open(): void {
      this.$refs.modal.open();
    },
  },
});
