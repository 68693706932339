import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "status-save-template" }
const _hoisted_2 = { class: "component-status-save-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusSave = _resolveComponent("StatusSave")!
  const _component_StatusSaveInfo = _resolveComponent("StatusSaveInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StatusSave, { status: _ctx.statusSaving }, null, 8, ["status"]),
      _createVNode(_component_StatusSaveInfo, {
        class: _normalizeClass(["component-status-save-info", _ctx.showPopupError ? 'component-status-save-info--active' : '']),
        status: _ctx.statusSaving,
        onTryAgain: _ctx.tryAgain
      }, null, 8, ["status", "class", "onTryAgain"])
    ])
  ]))
}