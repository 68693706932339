import { IchangeLog, IchangeLogDataApi, ChangeTypeApi, Change } from './';
import { i18n } from '@/plugins/i18n';
import { convertFormatDate } from '@/hooks/convertFormatDate';

const $t = i18n.global.t;

/**
 * @name changes
 * @description Define o texto que será captado de cada tipo de mudança do tema de acordo
 * com a linguagem selecionada na loja
 * @param change - tipo de alteração no tema
 * @returns {string}
 */
const changes = (change: Change): string => {
  const changes = {
    create: $t('changelog.create'),
    update: $t('changelog.update'),
    publish: $t('changelog.publish'),
    duplicate: $t('changelog.duplicate'),
    rename: $t('changelog.rename'),
    delete: $t('changelog.delete'),
    download: $t('changelog.download'),
    upload: $t('changelog.upload'),
    default: $t('changelog.default'),
  };
  return changes[change] || changes['default'];
};

/**
 * @name definesChange
 * @description Define o texto a ser exibido no Registro de Alterações do tema
 * @param idAction - número de "action" vindo da API de Changelog
 * @param detail - texto "detail" vindo da API de Changelog
 * @returns {string} texto da alteração de acordo com a linguagem da loja
 */
const definesChange = (idAction: number, detail: string | null): any => {
  switch (idAction) {
    case ChangeTypeApi.CREATE_BY_STORE:
    case ChangeTypeApi.CREATE_BY_UPDATE:
    case ChangeTypeApi.CREATE:
      return changes('create');
    case ChangeTypeApi.PUBLISHED:
      return changes('publish');
    case ChangeTypeApi.RENAMED:
      return `${changes('rename')} ${detail}`;
    case ChangeTypeApi.DUPLICATE:
      return changes('duplicate');
    case ChangeTypeApi.DOWNLOAD_ZIP:
      return changes('download');
    case ChangeTypeApi.UPLOAD_ZIP:
      return changes('upload');
    default:
      return changes('default');
  }
};

/**
 * @name defineHour
 * @description Converte a data e hora recebida da api para a hora no formato correto de
 * acordo com a linguagem da loja
 * @param {string} unformattedDate - data e hora vinda da api de Changelog
 * @returns {string} hora com minutos e segundos separados por ":" no sistema de 24 horas
 */
const defineHour = (unformattedDate: string): any => {
  const dateComplete = new Date(
    new Date(unformattedDate).toUTCString().substring(0, 25)
  );
  const hour = dateComplete.toLocaleTimeString($t('date-time-format'), {
    hour12: false,
  });
  return hour;
};

/**
 * @name ChangelogTheme
 * @description Classe que gera uma instância do registro de alteração de um tema com os
 * dados corretos.
 */
export class ChangelogTheme implements IchangeLog {
  public id = 0;
  public username = '';
  public date = '';
  public hour = '';
  public description = '';

  constructor(changelog: IchangeLogDataApi) {
    this.id = changelog.id;
    this.username = changelog.adm_user;
    this.date = convertFormatDate(changelog.updated_at);
    this.hour = defineHour(changelog.updated_at);
    this.description = definesChange(changelog.action, changelog.detail);
  }
}
