
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThemeDisplayButton',
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
});
