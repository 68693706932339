import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-zoom-image-overlay"
}
const _hoisted_2 = { class: "modal-zoom-image" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(_Transition, {
      name: "fade",
      type: "transition"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
              _createElementVNode("main", _hoisted_2, [
                _createElementVNode("img", {
                  src: _ctx.urlImage,
                  class: "modal-zoom-image__img"
                }, null, 8, _hoisted_3),
                _createVNode(_component_Icon, {
                  class: "modal-zoom-image__icon",
                  name: "times-circle",
                  "aria-hidden": "true",
                  onClick: _ctx.close
                }, null, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}