
import Icon from '@/components/Icon.vue';

import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    numberOfThemes: {
      type: Number,
      default: 0,
    },
    hiddenSeeAllThemes: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['see-all-themes', 'remove-old-themes'],
  computed: {
    showAlertIfThereAreMoreThan50Themes(): boolean {
      return this.numberOfThemes > 50;
    },
  },
  methods: {
    seeAllThemes(): void {
      this.$emit('see-all-themes');
    },
    removeOldThemes(): void {
      this.$emit('remove-old-themes');
    },
  },
});
