import { FieldValidation } from '@/models/composable/use-field-validation';
import { MaxFieldError } from '@/composable/use-field-validation/errors';

export class MaxLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly max: number) {}
  validate(input: object): null | Error {
    const keyObject = this.field as keyof object;
    const inputValue = input[keyObject] as number | string;
    if (typeof inputValue === 'number') {
      return inputValue > this.max ? new MaxFieldError(this.max) : null;
    }
    return inputValue.length > this.max ? new MaxFieldError(this.max) : null;
  }
}
