
import Subsession from '@/components/sessions/Subsession.vue';
import InputStandardImages from '@/components/editor/sidebar/input/standard-images/InputStandardImages.vue';
import Sidebar from '@/components/sidebar/BaseSideBar.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { defineComponent, onMounted } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useRemoveLastSeparator } from '@/support';

export default defineComponent({
  name: 'LogoSealSidebar',
  components: {
    Sidebar,
    Subsession,
    InputStandardImages,
    Alert,
  },
  setup() {
    onMounted(() => {
      useRemoveLastSeparator();
    });
    return {};
  },
  data() {
    return {
      dropdown: '',
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      imageListLogo: 'imageListLogo',
      imageListSeals: 'imageListSeals',
    }),
  },

  methods: {
    open(): void {
      this.$refs.sidebar.open();
    },
    ...mapActions('themeEditor', {
      apiUpdateImage: 'apiImageUpdate',
    }),
    updateLogo(image: { name: string; file: File }): void {
      this.apiUpdateImage({ path: 'logo', ...image });
    },
    updateSeal(image: { name: string; file: File }): void {
      this.apiUpdateImage({ path: 'seal', ...image });
    },
  },
});
