
import { defineComponent, PropType, ref } from 'vue';
import { useInputCheckbox, InputCheckboxInterface } from './module';
import useValidateValue from '@/hooks/validateValueToggleCheckbox';

export default defineComponent({
  name: 'InputCheckbox',

  props: {
    inputFields: {
      type: Object as PropType<InputCheckboxInterface>,
      required: true,
    },
  },
  emits: ['update-setting-current'],
  setup(props) {
    const { label, type, description, field, content, tab, value } =
      useInputCheckbox(
        props.inputFields.label,
        props.inputFields.type,
        props.inputFields.description,
        props.inputFields.field,
        props.inputFields.content,
        props.inputFields.tab,
        props.inputFields.value
      );
    const inputValue = ref(useValidateValue(value));

    return {
      label,
      type,
      description,
      field,
      content,
      tab,
      value,
      inputValue,
    };
  },
  watch: {
    inputValue(value): void {
      this.emitInputData(useValidateValue(value));
    },
  },
  methods: {
    emitInputData(value: boolean): void {
      this.$emit('update-setting-current', {
        key: this.field,
        value: value,
      });
    },
  },
});
