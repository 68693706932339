import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__tooltip-info-icon tray-theme-editor__tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          prefix: "fas",
          name: "info-circle",
          "aria-hidden": "true"
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(_ctx.dataPlacement)
    }, _toDisplayString(_ctx.info), 3), [
      [_vShow, _ctx.showTooltip]
    ])
  ]))
}