import { Memento } from '@/patterns/memento';
import { Backup } from '@/patterns/usecase/backup';
import { cloneDeep } from '@/plugins/lodash';

interface BackupManagerInterface {
  addState(): void;
  undo(): void;
  redo(): void;
}

export class BackupManager<T> implements BackupManagerInterface {
  private history: Memento[] = [];
  private currentIndex = -1;
  private backup = {} as Backup<T>;

  init(backup: Backup<T>): void {
    this.backup = backup;
  }

  addState(): void {
    if (this.currentIndex + 1 < this.history.length) {
      this.history.splice(this.currentIndex + 1);
    }
    const snapshot = this.backup.save();
    this.history.push(cloneDeep(snapshot));
    this.currentIndex++;
  }

  undo(): void {
    const prevState = this.history[this.currentIndex - 1];
    if (prevState === undefined) {
      this.backup.restore(undefined);
      return;
    }
    const undoData = cloneDeep(prevState);
    this.backup.restore(undoData);
    this.currentIndex--;
  }

  redo(): void {
    const nextState = this.history[this.currentIndex + 1];
    if (nextState === undefined) {
      this.backup.restore(undefined);
      return;
    }
    const undoData = cloneDeep(nextState);
    this.backup.restore(undoData);
    this.currentIndex++;
  }
}
