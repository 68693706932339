import {
  MutationTree,
  ActionTree,
  ActionContext,
  Module,
  GetterTree,
} from 'vuex';

type User = {
  username: string;
};
type UserStore = {
  code: string;
  urlStore: string;
  urlPreview: string;
};
type TokenParams = {
  themeCode: string;
  themeId: string;
};
type EditorUrls = {
  storeThemes: string;
  panel: string;
  panelListThemes: string;
};

export type UserModule = Module<State, any>;

export interface State {
  user: User;
  store: UserStore;
  tokenParams: TokenParams;
  urls: EditorUrls;
  isLogged: boolean;
}

export interface Getters extends GetterTree<State, any> {
  /**
   * @name storeGet
   * @param state {State} - Estado da store
   * @description Retorna a url da loja e do preview.
   * @return UserStore
   */
  storeGet: (state: State) => UserStore;

  /**
   * @name tokenParamsGet
   * @param state {State} - Estado da store
   * @description Retorna os atributos de redirecionamento do token providos do opencode.
   * @return TokenParams
   */
  tokenParamsGet: (state: State) => TokenParams;

  /**
   * @name urlsGet
   * @param state {State} - Estado da store
   * @description Retorna as urls do estado de user
   * @return TokenParams
   */
  urlsGet: (state: State) => EditorUrls;
}

export enum Mutations {
  /**
   * @name SET_STORE_CONFIG
   * @description - Mutation para salvar as configurações da loja retirados do token.
   */
  SET_STORE_CONFIG = 'SET_STORE_CONFIG',

  /**
   * @name RECOVERY_USER_STORE_DATA
   * @description - Mutation para restaurar as configurações da store.
   */
  RECOVERY_USER_STORE_DATA = 'RECOVERY_USER_STORE_DATA',
}

export interface MutationsInterface extends MutationTree<any> {
  /**
   * @name [Mutations.SET_STORE_CONFIG]
   *
   * @param state {State} - Estado do store
   * @description Salva as configurações contidas no token na store.
   * @return void
   */
  [Mutations.SET_STORE_CONFIG](state: State): void;

  /**
   * @name [Mutations.RECOVERY_USER_STORE_DATA]
   *
   * @param state {State} - Estado do store
   * @description Restaura as configurações da store user se existir no local storage.
   * @return void
   */
  [Mutations.RECOVERY_USER_STORE_DATA](state: State): void;
}

export type Context = ActionContext<State, any>;
export interface ActionsInterface extends ActionTree<State, any> {
  /**
   * @name login
   * @param context {Context} - Contexto de ações do Vuex
   * @description Gatilho para login do usuário.
   * @return void - Sem retorno
   */
  login: (ctx: Context) => void;

  /**
   * @name recoveryStoreUser
   * @param context {Context} - Contexto de ações do Vuex
   * @description Reescreve a store de user se o usuário estiver logado e exixtir no localStorage.
   * @return void - Sem retorno
   */
  recoveryStoreUser: (ctx: Context) => void;
}
