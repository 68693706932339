import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { SealApiClientUrlsInterface } from './SealApiClientUrls.interface';
import { SealApiClientInterface } from './SealApiClient.interface';

/**
 * @Name SealApiClientModel
 * @description Implementa a interface SealApiClientInterface
 */
export class SealApiClientModel implements SealApiClientInterface {
  private readonly urls!: SealApiClientUrlsInterface;

  constructor(urls: SealApiClientUrlsInterface) {
    this.urls = urls;
  }

  updateSeal(seal: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.updateSeal,
      requiresToken: false,
      payload: seal,
    };

    return HttpClient.post(params);
  }
  getSeal(seal: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getSeal,
      requiresToken: false,
      payload: seal,
    };
    return HttpClient.post(params);
  }
}
