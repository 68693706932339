import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex f-column input-file-info" }
const _hoisted_2 = { class: "input-file-info__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "input-file-info__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    (Array.isArray(_ctx.description))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.description, (desc, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: "input-file-info__description"
            }, _toDisplayString(desc), 1))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.description), 1))
  ]))
}