import { Memento } from '@/patterns/memento';

export class ConcreteMemento<T> implements Memento {
  constructor(private name: string, private date: string, private data: T) {}

  getName(): string {
    return this.name;
  }
  getDate(): string {
    return this.date;
  }
  getData(): T {
    return this.data;
  }
}
