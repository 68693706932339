import { createStore, Store } from 'vuex';
import { State as ThemeEditor } from '@/models/store/theme-editor';

import themeEditor from './theme-editor';
import { State as User } from '@/models/store/user';

import user from './user';
import { actions } from './root-store/actions';

export interface State {
  themeEditor: ThemeEditor;
  user: User;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

const store = createStore({
  actions,
  modules: {
    user,
    themeEditor,
  },
});

export default store;
