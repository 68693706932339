import { InputSelect } from './InputSelect.model';
import { OptionInterface } from './InputSelect.interface';

function useInputSelect(
  label: string,
  type: string,
  description: string[],
  field: string,
  options: OptionInterface[],
  tab: string,
  value: string
): InputSelect {
  return new InputSelect({
    label,
    type,
    description,
    field,
    options,
    tab,
    value,
  });
}

export { useInputSelect };
