import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { LogoApiClientUrlsInterface } from './LogoApiClientUrls.interface';
import { LogoApiClientInterface } from './LogoApiClient.interface';

/**
 * @Name LogoApiClientInterface
 * @description Implementa a interface LogoApiClientInterface
 */
export class LogoApiClientModel implements LogoApiClientInterface {
  private readonly urls!: LogoApiClientUrlsInterface;

  constructor(urls: LogoApiClientUrlsInterface) {
    this.urls = urls;
  }

  updateLogo(logo: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.updateLogo,
      requiresToken: false,
      payload: logo,
    };

    return HttpClient.post(params);
  }
  getLogo(logo: FormData): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getLogo,
      requiresToken: false,
      payload: logo,
    };
    return HttpClient.post(params);
  }
}
