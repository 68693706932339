/**
 * Retorna o valor do parametro desejado caso exista na URL
 * @param {String} path, url
 * @param {String} queryParam, query param
 * @return {String} Valor do parametro
 */
export function getQueryParam(path: string, queryParam: string): string {
  const url = path.split('?');
  if (url.length <= 1) {
    return '';
  }

  const urlParams = url[url.length - 1].split('&');
  for (let i = 0; i < urlParams.length; i += 1) {
    const paramValue = urlParams[i].split('=');

    if (paramValue[0] === queryParam) {
      return paramValue[1];
    }
  }

  return '';
}
