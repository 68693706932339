
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    paid: {
      type: Boolean,
      required: true,
    },
    updated: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'theme-edit',
    'theme-html-edit',
    'theme-rename',
    'theme-change-log',
    'theme-duplicate',
    'theme-updated-version',
    'theme-delete',
  ],

  data() {
    return {
      dropdown: false,
      options: [
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.edit-theme'
          ),
          event: 'theme-edit',
          textColor: '',
          show: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.edit-html'
          ),
          event: 'theme-html-edit',
          textColor: '',
          show: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.edit-name'
          ),
          event: 'theme-rename',
          textColor: '',
          show: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.change-log'
          ),
          event: 'theme-change-log',
          textColor: '',
          show: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.duplicate'
          ),
          event: 'theme-duplicate',
          textColor: '',
          show: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.update-version'
          ),
          event: 'theme-updated-version',
          textColor: '',
          show: false,
          dotShow: true,
        },
        {
          label: this.$t(
            'components.unpublished-list-themes.theme-item.button.options.remove'
          ),
          event: 'theme-delete',
          textColor: 'text-color-red',
          show: true,
        },
      ],
    };
  },
  mounted(): void {
    if (!this.paid) {
      this.options.forEach((element: any) => {
        if (
          element.event === 'theme-delete' ||
          element.event === 'theme-edit'
        ) {
          return (element.show = true);
        }
        return (element.show = false);
      });
    }
    if (!this.updated) {
      this.options[5].show = true;
    }
  },
  methods: {
    openCloseDropdown(value: boolean): void {
      this.dropdown = value;
    },
  },
});
