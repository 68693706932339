
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  name: 'Icon',
  components: {
    FontAwesomeIcon,
  },

  props: {
    name: {
      type: String,
      default: 'user',
    },

    /**
     * Font awesome style
     * @fa solid
     * @far regular
     */
    prefix: {
      type: String,
      default: 'far',
    },

    flip: Boolean,
    rotate: {
      type: Number,
      default: 0,
    },
  },
});
