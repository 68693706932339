
import Icon from '@/components/Icon.vue';

import { Status } from './module';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'StatusSaveInfo',
  components: {
    Icon,
  },
  props: {
    status: {
      type: String as PropType<Status>,
      required: true,
    },
  },

  emits: ['try-again'],
});
