import {
  CustomPageApiClientUrlsInterface,
  CustomPageApiClientInterface,
  CustomPageApiClientModel,
} from '@/models/api-client/custom-page';

import { config } from '@/config';

const urls: CustomPageApiClientUrlsInterface = config.customPage.apiUrls;
const CustomPageApiClient: CustomPageApiClientInterface =
  new CustomPageApiClientModel(urls);
export default CustomPageApiClient;
