import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-file-preview" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "input-file-preview__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("img", {
      loading: "lazy",
      src: _ctx.image,
      alt: _ctx.field,
      class: "input-file-preview__image",
      onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadImageError && _ctx.onLoadImageError(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_component_Icon, {
        prefix: "far",
        name: "edit",
        "aria-hidden": "true",
        class: "input-file-preview__icon"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('defaults.inputs-file.edit-image')), 1)
    ])
  ]))
}