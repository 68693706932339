
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    const toggleBetaList = 'beta_list_store';
    const activeEditorList = 'editor_list';

    const hasBeta = ref();

    window.onload = (): void => {
      setTimeout(() => {
        hasBeta.value = sessionStorage.getItem(toggleBetaList) === 'true';
      }, 500);
    };

    const backLink = 'https://opencode.tray.com.br/v2/themes/';

    const activeClassicList = (): void => {
      sessionStorage.setItem(activeEditorList, 'classic');
      window.open(backLink, '_self');
    };

    return {
      hasBeta,
      backLink,
      activeClassicList,
    };
  },
});
