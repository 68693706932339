import {
  ValidationBuilder,
  ValidationComposite,
} from '@/composable/use-field-validation';
import {
  InputValidation,
  UseValidation,
} from '@/models/composable/use-field-validation';

/**
 * @name useValidate
 * @description Validador de campos
 * @param {InputValidation} input - Objeto com os campos e regras de validação.
 * @returns {UseValidation} - Retorna um objeto com os métodos de validação.
 */
export function useValidate(input: InputValidation): UseValidation {
  const { rules, field } = input;
  const validators: string[] = [];
  const Builder = ValidationBuilder.field(field);
  function prepareValidation(): void {
    if (rules.includes('|')) {
      validators.push(...rules.split('|'));
    } else {
      validators.push(rules);
    }
    validators.forEach((validator) => {
      const [key, value] = validator.split(':');
      if (key === 'required' && value === 'true') {
        Builder.required();
      }
      if (key === 'email') {
        Builder.email();
      }
      if (key === 'min') {
        Builder.min(parseInt(value));
      }
      if (key === 'max') {
        Builder.max(parseInt(value));
      }
    });
  }
  function createValidation(): void {
    prepareValidation();
  }
  function validation(inputValue: any): string | null {
    const valid = ValidationComposite.build([...Builder.build()]);
    return valid.validate(field, {
      [field]: inputValue,
    });
  }
  return {
    createValidation,
    validation,
  };
}
