import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "status-theme" }
const _hoisted_2 = { class: "status-theme__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.descriptionStatus), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["status-theme__icon-rounded", 
        _ctx.updated
          ? 'dot-alert dot-alert__sucess--big'
          : 'dot-alert dot-alert__warning--big'
      ])
    }, null, 2)
  ]))
}