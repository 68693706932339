import { InputCheckbox, InputCheckboxAdicional } from './InputCheckbox.model';

function useInputCheckbox(
  label: string,
  type: string,
  description: string[],
  field: string,
  content: string,
  tab: string,
  value: boolean
): InputCheckbox {
  return new InputCheckbox({
    label,
    type,
    description,
    field,
    content,
    tab,
    value,
  });
}

function useInputCheckboxAdicional(
  type: string,
  field: string,
  content: string,
  tab: string,
  value: boolean
): InputCheckboxAdicional {
  return new InputCheckboxAdicional({
    type,
    field,
    content,
    tab,
    value,
  });
}

export { useInputCheckbox, useInputCheckboxAdicional };
