import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { ImageApiClientUrlsInterface } from './ImageApiClientUrls.interface';
import { ImageApiClientInterface } from './ImageApiClient.interface';
export class ImageApiClientModel implements ImageApiClientInterface {
  private readonly urls!: ImageApiClientUrlsInterface;

  constructor(urls: ImageApiClientUrlsInterface) {
    this.urls = urls;
  }

  getImages(): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getImages,
      requiresToken: true,
    };
    return HttpClient.get(params);
  }

  updateImage(
    path: 'logo' | 'seal' | 'button',
    formData: FormData
  ): Promise<AxiosResponse> {
    const params: HttpRequestParamsInterface = {
      url: `${this.urls.updateImage}/${path}`,
      requiresToken: false,
      payload: formData,
    };

    return HttpClient.post(params);
  }
}
