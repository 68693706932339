import { IthemeDataApi } from '@/models/list/index';
import { IthemeDataListingStore } from '@/models/store/theme-editor';
import { convertFormatDate } from '@/hooks/convertFormatDate';

/**
 * @name generateThemeFromState
 * @description gera um tema com os dados validados para o state do Vuex
 * @param {IthemeDataApi} themeApi tema gerado pelo getList()
 * @returns {IthemeDataListingStore}
 */
export function generateThemeFromState(
  themeApi: IthemeDataApi
): IthemeDataListingStore {
  const updateAt = convertFormatDate(themeApi.updated_at);
  const createdAt = convertFormatDate(themeApi.created_at);

  const validThumb = (): string =>
    themeApi.thumb ? themeApi.thumb : require('@/assets/img/thumbEmpty.png');
  const validLastDate = (): string => (updateAt == '' ? createdAt : updateAt);

  return {
    id: themeApi.id,
    entity_id: themeApi.entity_id,
    name: themeApi.name,
    code: themeApi.code,
    app_theme: themeApi.app_theme,
    createdAt: createdAt || '',
    preview: themeApi.preview || '',
    published: themeApi.published === 1,
    has_structure: themeApi.has_structure,
    thumb: validThumb(),
    updatedAt: validLastDate(),
    updated: themeApi.updated,
    paid: themeApi.paid,
    payment: themeApi.payment,
    version: '-.-.-',
    urlStore: '',
  };
}
