
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  name: 'Tooltip',
  components: {
    Icon,
  },
  props: {
    info: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      required: false,
    },
    placement: {
      type: String,
      default: 'top',
    },
    positionTop: {
      type: String,
      default: 'center',
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dataPlacement(): string {
      return `tray-theme-editor__tooltip-text tray-theme-editor__tooltip-text-${this.positionTop} tray-theme-editor__tooltip-text-${this.placement}`;
    },
  },
});
