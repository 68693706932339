import { InputSelectInterface, OptionInterface } from './InputSelect.interface';

export class InputSelect implements InputSelectInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public options = [] as OptionInterface[];
  public tab = '';
  public value = '';

  constructor(dto: InputSelectInterface) {
    Object.assign(this, dto);
  }
}
