import { PersistStoreModel } from '@/models/composable';

export class PersistStore<T> implements PersistStoreModel<T> {
  constructor(private readonly prefix: string) {
    this.prefix = prefix;
  }
  save(store: object): void {
    localStorage.setItem(this.prefix, JSON.stringify(store));
  }
  restore(): T | null {
    const store = localStorage.getItem(this.prefix);
    if (!store) return null;
    return JSON.parse(store) as T;
  }
}

export const persistStore = <T>(storeName: string): PersistStore<T> => {
  return new PersistStore(`tray-theme-${storeName}-data`);
};
