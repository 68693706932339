import { InputColor } from './InputColor.model';

function useInputColor(
  label: string,
  type: string,
  description: string[],
  placeholder: string,
  field: string,
  validation: string,
  tab: string,
  value: string
): InputColor {
  return new InputColor({
    label,
    type,
    description,
    placeholder,
    field,
    validation,
    tab,
    value,
  });
}

export { useInputColor };
