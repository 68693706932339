import { DefaultValues } from '@/models/store/config';

export const defaultValues: DefaultValues = {
  pages: {
    list: [
      {
        slug: 'home',
        name: 'Página Inicial',
        url: '/',
        active: '1',
        template: '',
        id: '',
        conteudo: '',
        info: '',
        metatags: '',
        seo: '',
        title: '',
      },
      {
        slug: 'buscar',
        name: 'Buscar',
        url: '/loja/busca.php',
        active: '1',
        template: '',
        id: '',
        conteudo: '',
        info: '',
        metatags: '',
        seo: '',
        title: '',
      },
      {
        slug: 'central-do-cliente',
        name: 'Login',
        url: '/central-do-cliente',
        active: '1',
        template: '',
        id: '',
        conteudo: '',
        info: '',
        metatags: '',
        seo: '',
        title: '',
      },
      {
        slug: 'cadastro',
        name: 'Cadastro',
        url: '/loja/cadastro_layout.php',
        active: '1',
        template: '',
        id: '',
        conteudo: '',
        info: '',
        metatags: '',
        seo: '',
        title: '',
      },
      {
        slug: 'contato',
        name: 'Contato',
        url: '/loja/contato.php',
        active: '1',
        template: '',
        id: '',
        conteudo: '',
        info: '',
        metatags: '',
        seo: '',
        title: '',
      },
    ],
  },
  resolutions: {
    list: [
      {
        name: 'desktop',
        width: '100%',
        height: '100%',
        icon: 'desktop',
      },
      {
        name: 'tablet',
        width: '768px',
        height: '700px',
        icon: 'mobile-alt',
      },
      {
        name: 'mobile',
        width: '576px',
        height: '700px',
        icon: 'tablet-alt',
      },
      {
        name: 'custom',
        width: '1000px',
        height: '700px',
        icon: 'arrows-alt-h',
      },
    ],
  },
  images: {
    logo: [
      {
        url: '',
        key: 'favicon',
      },
      {
        url: '',
        key: 'store_logo',
      },
      {
        url: '',
        key: 'logo_mobile',
      },
      {
        url: '',
        key: 'print_logo',
      },
      {
        url: '',
        key: 'store_emails_logo',
      },
    ],
    seal: [
      {
        url: '',
        key: 'emphasis',
      },
      {
        url: '',
        key: 'free_shipping',
      },
      {
        url: '',
        key: 'launch',
      },
      {
        url: '',
        key: 'on_request',
      },
      {
        url: '',
        key: 'additional_button',
      },
    ],
    button: [
      {
        url: '',
        key: 'purchase',
      },
      {
        url: '',
        key: 'button_buy_together',
      },
      {
        url: '',
        key: 'proceed_buy',
      },
      {
        url: '',
        key: 'finish',
      },
      {
        url: '',
        key: 'discount_coupon',
      },
      {
        url: '',
        key: 'calculate_shipping',
      },
      {
        url: '',
        key: 'simulate_shipping',
      },
      {
        url: '',
        key: 'button_unavailable',
      },
      {
        url: '',
        key: 'login',
      },
      {
        url: '',
        key: 'change_purchase',
      },
      {
        url: '',
        key: 'button_negotiate',
      },
      {
        url: '',
        key: 'button_buy_negotiate',
      },
      {
        url: '',
        key: 'button_ok_negotiate',
      },
      {
        url: '',
        key: 'keep_buying',
      },
      {
        url: '',
        key: 'register',
      },
      {
        url: '',
        key: 'to_send',
      },
      {
        url: '',
        key: 'send_payment',
      },
      {
        url: '',
        key: 'choose',
      },
      {
        url: '',
        key: 'button_save_list_purchase',
      },
      {
        url: '',
        key: 'proceed_registration',
      },
      {
        url: '',
        key: 'exchange_purchase_data',
      },
      {
        url: '',
        key: 'change_payment_form',
      },
      {
        url: '',
        key: 'button_gift',
      },
    ],
  },
};
