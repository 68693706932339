import { Backup, BackupManager } from '@/patterns/usecase/backup';
import {
  BackupApp,
  BackupGenerate,
  BackupRedo,
  BackupStorage,
  BackupUndo,
} from '@/patterns/usecase/useBackup';

export type UseHistoric<T = any> = {
  createBackup(data: T): void;
  setUndo(): T | null;
  setRedo(): T | null;
};

enum Keys {
  BACKUP = 'Backup',
  UNDO = 'Undo',
  REDO = 'Redo',
}

export const useHistoric = <T>(): UseHistoric<T> => {
  const backup = new Backup<T>();
  const backupManager = new BackupManager<T>();
  backupManager.init(backup);
  const backupStorage = new BackupStorage<T>(backup, backupManager);
  const backupGenerate = new BackupGenerate<T>(backupStorage);
  const backupUndo = new BackupUndo<T>(backupStorage);
  const backupRedo = new BackupRedo<T>(backupStorage);
  const backupApp = new BackupApp();
  backupApp.addCommand(Keys.BACKUP, backupGenerate);
  backupApp.addCommand(Keys.UNDO, backupUndo);
  backupApp.addCommand(Keys.REDO, backupRedo);
  function createBackup(data: T): void {
    backup.init(data);
    backupApp.executeCommand(Keys.BACKUP);
  }
  function setUndo(): T | null {
    backupApp.executeCommand(Keys.UNDO);
    const undo = backup.getData();
    if (undo && Object.keys(undo).length) {
      return undo;
    }
    return null;
  }
  function setRedo(): T | null {
    backupApp.executeCommand(Keys.REDO);
    const redo = backup.getData();
    if (redo && Object.keys(redo).length) {
      return redo;
    }
    return null;
  }
  return {
    createBackup,
    setRedo,
    setUndo,
  };
};
