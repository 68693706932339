type ValueSettings = string | number | boolean;

export default (value: ValueSettings): boolean => {
  if (
    value === 'on' ||
    value === 'true' ||
    value === '1' ||
    value === 1 ||
    value === true
  ) {
    return true;
  }
  if (
    value === 'off' ||
    value === 'false' ||
    value === '0' ||
    value === 0 ||
    value === false
  ) {
    return false;
  }
  return false;
};
