
import Icon from '@/components/Icon.vue';
import Modal from '@/components/modals/base-modal/BaseModal.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RemoveOldThemesModal',
  components: {
    Modal,
    Icon,
  },
  methods: {
    open(): void {
      this.$refs.modal.open();
    },
  },
});
