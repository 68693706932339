import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__input-checkbox" }
const _hoisted_2 = { class: "tray-theme-editor__input-checkbox__section" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(["tray-theme-editor__input-checkbox__form-adicional", _ctx.tab])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.field,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          type: "checkbox",
          "true-value": "true",
          "false-value": "false",
          class: "tray-theme-editor__input-checkbox__content"
        }, null, 8, _hoisted_3), [
          [_vModelCheckbox, _ctx.inputValue]
        ]),
        _createElementVNode("label", {
          class: "tray-theme-editor__input-checkbox__label",
          for: _ctx.field
        }, _toDisplayString(_ctx.content), 9, _hoisted_4)
      ])
    ])
  ], 2))
}