import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex f-between wrapper-input-file" }
const _hoisted_2 = { class: "wrapper_input" }
const _hoisted_3 = ["id", "required", "name"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFileImageInfo = _resolveComponent("InputFileImageInfo")!
  const _component_InputFileImageLoading = _resolveComponent("InputFileImageLoading")!
  const _component_InputStandardImagesPreview = _resolveComponent("InputStandardImagesPreview")!
  const _component_InputFileImageEmpty = _resolveComponent("InputFileImageEmpty")!
  const _component_InputFileImageError = _resolveComponent("InputFileImageError")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _mergeProps(_ctx.$attrs, {
      class: ["f-family d-flex f-column container-input-file", _ctx.tab]
    }), [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_InputFileImageInfo, {
          label: _ctx.label,
          description: _ctx.description
        }, null, 8, ["label", "description"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", {
            id: _ctx.field,
            required: _ctx.required,
            class: "wrapper_input__input",
            type: "file",
            accept: "image/*",
            name: _ctx.field,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.callback && _ctx.callback(...args)))
          }, null, 40, _hoisted_3),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_InputFileImageLoading, { key: 0 }))
            : (_openBlock(), _createElementBlock("label", {
                key: 1,
                class: _normalizeClass([_ctx.classInputError, "wrapper_input__label"]),
                for: _ctx.field
              }, [
                (_ctx.isExistImageAndNotLoading)
                  ? (_openBlock(), _createBlock(_component_InputStandardImagesPreview, {
                      key: 0,
                      field: _ctx.field,
                      image: _ctx.image,
                      onLoadImageError: _ctx.onLoadImageError
                    }, null, 8, ["field", "image", "onLoadImageError"]))
                  : (_openBlock(), _createBlock(_component_InputFileImageEmpty, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('defaults.inputs-file.add-image')), 1)
                      ]),
                      _: 1
                    }))
              ], 10, _hoisted_4))
        ])
      ]),
      (_ctx.isErrorToField)
        ? (_openBlock(), _createBlock(_component_InputFileImageError, {
            key: 0,
            message: _ctx.message.error
          }, null, 8, ["message"]))
        : _createCommentVNode("", true)
    ], 16),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}