
import Icon from '@/components/Icon.vue';
import BaseModalFooter from '@/components/modals/base-modal/BaseModalFooter.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Modal',
  components: {
    Icon,
    BaseModalFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    labelButton: {
      type: String,
      required: true,
    },
    typeButton: {
      type: String,
      required: true,
      validator: (value: string): any =>
        ['delete', 'continue', 'save', 'cancel'].includes(value),
    },
    info: {
      type: String,
      default: '',
    },
    classStyle: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'continue', 'save', 'cancel'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open(): void {
      this.show = true;
    },
    close(): void {
      this.show = false;
      this.$emit('cancel', false);
    },
    actions(action: 'delete' | 'continue' | 'save' | 'cancel'): void {
      this.$emit(action, true);
      this.show = false;
    },
  },
});
