
import { defineComponent, onMounted, ref } from 'vue';
import Icon from '@/components/Icon.vue';
import { mapGetters, mapMutations } from 'vuex';
import { Mutations } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'CustomResolutionFields',
  components: {
    Icon,
  },
  setup() {
    const firstInput = ref<HTMLInputElement>(document.createElement('input'));
    onMounted(() => {
      firstInput.value.focus();
    });
    return {
      firstInput,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      activeResolution: 'previewSelectedResolution',
      resolutions: 'previewResolutions',
    }),
    resolutionIsCustom(): boolean {
      return this.activeResolution.name === 'custom';
    },
  },
  methods: {
    ...mapMutations('themeEditor', {
      setResolution: Mutations.PREVIEW_SET_RESOLUTION,
      setCustomResolution: Mutations.PREVIEW_UPDATE_CUSTOM_RESOLUTION,
    }),
    changeResolution(field: { key: string; value: string }): void {
      this.setCustomResolution(field);
    },
    convertPxToNumber(value: string): number {
      if (value.includes('px')) {
        return Number(value.replace('px', ''));
      }
      return Number(value.replace('%', ''));
    },
    convertNumberToPx(value: number): string {
      return `${value}px`;
    },
  },
});
