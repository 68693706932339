import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tray-theme-editor" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "theme-list-page skeletons" }
const _hoisted_4 = { class: "theme-list-page__container" }
const _hoisted_5 = { class: "theme-list-page__title skeletons__title-published" }
const _hoisted_6 = { class: "theme-list-page__theme-wrapper" }
const _hoisted_7 = {
  id: "published-theme",
  class: "theme-list-page__theme-published skeletons__card-theme"
}
const _hoisted_8 = { class: "theme-list-page__theme-logo skeletons__card-logo-seals" }
const _hoisted_9 = { class: "theme-list-page__container" }
const _hoisted_10 = { class: "theme-list-page__title skeletons__title-not-published" }
const _hoisted_11 = { class: "theme-list-page__theme-wrapper skeletons__card-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_ChangeLogTheme = _resolveComponent("ChangeLogTheme")!
  const _component_RemoveThemeModal = _resolveComponent("RemoveThemeModal")!
  const _component_RenameThemeModal = _resolveComponent("RenameThemeModal")!
  const _component_BuyThemeModal = _resolveComponent("BuyThemeModal")!
  const _component_EditThemeModal = _resolveComponent("EditThemeModal")!
  const _component_NavigationThemeList = _resolveComponent("NavigationThemeList")!
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_AlertBeta = _resolveComponent("AlertBeta")!
  const _component_ThemeDisplay = _resolveComponent("ThemeDisplay")!
  const _component_ThemeDisplayEmpty = _resolveComponent("ThemeDisplayEmpty")!
  const _component_ThemeLogoSeals = _resolveComponent("ThemeLogoSeals")!
  const _component_UnpublishedThemeList = _resolveComponent("UnpublishedThemeList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Notification, {
      ref: "notification",
      duration: _ctx.duration
    }, null, 8, ["duration"]),
    _createVNode(_component_ChangeLogTheme, {
      ref: "sidebar",
      theme: _ctx.themeSelected,
      changelog: _ctx.changeLogList,
      onClose: _ctx.resetSelectedTheme
    }, null, 8, ["theme", "changelog", "onClose"]),
    _createVNode(_component_RemoveThemeModal, {
      ref: "modalDeleteTheme",
      theme: _ctx.themeSelected,
      title: _ctx.$t('components.modals.titles.attention'),
      onDelete: _ctx.themeDelete
    }, null, 8, ["theme", "title", "onDelete"]),
    _createVNode(_component_RenameThemeModal, { ref: "modalRename" }, null, 512),
    _createVNode(_component_Notification, { ref: "notification" }, null, 512),
    _createVNode(_component_BuyThemeModal, {
      ref: "modalBuyTheme",
      theme: _ctx.themeSelected,
      title: _ctx.$t('components.modals.titles.buy-theme'),
      onContinue: _ctx.themeBuy
    }, null, 8, ["theme", "title", "onContinue"]),
    _createVNode(_component_EditThemeModal, {
      ref: "modalEditTheme",
      onContinue: _ctx.themeEditingContinue
    }, null, 8, ["onContinue"]),
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_NavigationThemeList, {
        "url-view-store": _ctx.theme.urlStore,
        "url-theme-store": _ctx.externalUrl.storeThemes,
        "url-theme-return-painel": _ctx.externalUrl.panel
      }, null, 8, ["url-view-store", "url-theme-store", "url-theme-return-painel"])
    ]),
    (_ctx.themeLoading.status === 'loading' || _ctx.initialLoading === 'loading')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoadingPage)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_3, [
      _createVNode(_component_AlertBeta, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('pages.theme-list.alerts.classic-list')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.labels.themePublished), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.isTheme && _ctx.isPublishedTheme)
              ? (_openBlock(), _createBlock(_component_ThemeDisplay, {
                  key: 0,
                  theme: _ctx.theme,
                  onViewStore: _ctx.viewStore,
                  onEditTheme: _ctx.themeEdit,
                  onUpdateThemeVersion: _ctx.themeUpdatedVersion,
                  onThemeChangeLog: _ctx.themeChangeLog,
                  onDuplicateTheme: _ctx.themeDuplicate,
                  onThemeRename: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalRename(_ctx.theme)))
                }, null, 8, ["theme", "onViewStore", "onEditTheme", "onUpdateThemeVersion", "onThemeChangeLog", "onDuplicateTheme"]))
              : (_ctx.isTheme && !_ctx.isPublishedTheme)
                ? (_openBlock(), _createBlock(_component_ThemeDisplayEmpty, {
                    key: 1,
                    "url-theme-store": _ctx.externalUrl.storeThemes,
                    label: _ctx.$t('components.theme-display-empty.no-themes-installed'),
                    description: 
              _ctx.$t(
                'components.theme-display-empty.go-to-theme-store-and-choose-your-store-layout'
              )
            ,
                    "button-theme-store": true
                  }, null, 8, ["url-theme-store", "label", "description"]))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.isTheme && _ctx.isPublishedTheme)
              ? (_openBlock(), _createBlock(_component_ThemeLogoSeals, {
                  key: 0,
                  "show-logo": true
                }))
              : _createCommentVNode("", true),
            (_ctx.isTheme && !_ctx.isPublishedTheme)
              ? (_openBlock(), _createBlock(_component_ThemeLogoSeals, {
                  key: 1,
                  "show-logo": false
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_9, [
        _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.labels.themeUnpublished), 1),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.isThemeList)
            ? (_openBlock(), _createBlock(_component_UnpublishedThemeList, {
                key: 0,
                themes: _ctx.themes,
                onThemeEdit: _ctx.themeEdit,
                onThemeHtmlEdit: _ctx.themeHtmlEdit,
                onThemeChangeLog: _ctx.themeChangeLog,
                onThemeDuplicate: _ctx.themeDuplicate,
                onThemeUpdatedVersion: _ctx.themeUpdatedVersion,
                onThemeDelete: _ctx.openModalRemove,
                onThemeBuy: _ctx.openModalBuyTheme,
                onThemeChangePayment: _ctx.openChangePayment,
                onThemePublish: _ctx.themePublish,
                onThemePreview: _ctx.viewStore,
                onThemeRename: _ctx.openModalRename,
                onThemeShowMorePages: _ctx.loadMorePages
              }, null, 8, ["themes", "onThemeEdit", "onThemeHtmlEdit", "onThemeChangeLog", "onThemeDuplicate", "onThemeUpdatedVersion", "onThemeDelete", "onThemeBuy", "onThemeChangePayment", "onThemePublish", "onThemePreview", "onThemeRename", "onThemeShowMorePages"]))
            : (!_ctx.isThemeList && _ctx.loadList)
              ? (_openBlock(), _createBlock(_component_ThemeDisplayEmpty, {
                  key: 1,
                  "url-theme-store": _ctx.externalUrl.storeThemes,
                  "list-unpublished": true,
                  label: _ctx.$t('components.theme-display-empty.no-themes-installed'),
                  description: 
            _ctx.$t(
              'components.theme-display-empty.check-out-more-options-in-the-theme-store'
            )
          ,
                  "button-theme-store": true
                }, null, 8, ["url-theme-store", "label", "description"]))
              : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}