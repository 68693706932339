
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'InputFileInfo',
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: [String, Array],
      default: '',
    },
  },
});
