import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unpublished-theme-search" }
const _hoisted_2 = { class: "unpublished-theme-search__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon",
      name: "search",
      prefix: "fas"
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}