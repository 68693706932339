import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faBookmark,
  faTimesCircle,
  faCopy,
  faEdit,
  faStar,
  faPlusSquare,
  faFile,
  faTrashAlt,
  faFileImage,
  faImage,
} from '@fortawesome/free-regular-svg-icons';

const iconsRegular = [
  faBookmark,
  faCheckCircle,
  faTimesCircle,
  faCopy,
  faEdit,
  faStar,
  faPlusSquare,
  faFile,
  faTrashAlt,
  faFileImage,
  faImage,
] as IconDefinition[];

export default iconsRegular;
