import { InputStandardImagesInterface, FileObjectInterface } from './';

export class InputStandardImages implements InputStandardImagesInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public validation = '';
  public tab = '';

  constructor(dto: InputStandardImagesInterface) {
    Object.assign(this, dto);
  }

  changeFile(name: string, file: File): FileObject {
    return { name, file } as FileObject;
  }
}

export class FileObject implements FileObjectInterface {
  public name = '';
  public file = new File([], '');

  constructor(dto: FileObjectInterface) {
    Object.assign(this, dto);
  }
}
