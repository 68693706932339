import { InputTabInterface, OptionInterface } from './InputTab.interface';

export class InputTab implements InputTabInterface {
  public type = '';
  public field = '';
  public value = '';
  public options = [] as OptionInterface[];

  constructor(dto: InputTabInterface) {
    Object.assign(this, dto);
  }
}
