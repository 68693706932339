/* eslint @typescript-eslint/explicit-function-return-type: "off", no-var: "off" */
import apiClient from '@/api-client';

window.dataLayer = window.dataLayer || [];

async function requestDataLayer() {
  return apiClient.dataLayer.getStoreInfo();
}
async function getStoreInfo() {
  const responseDataLayer = await requestDataLayer();
  window.dataLayer.push(responseDataLayer.data);
}

async function injectScriptGTMAndDataLayer() {
  await getStoreInfo();

  const elemScript = document.createElement('script');
  elemScript.innerHTML = `(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-5C3PHPS');`;
  document.head.prepend(elemScript);
}
injectScriptGTMAndDataLayer();
