import { ActionsInterface } from '@/models/store/root-store';
import { useHistoric } from '@/plugins/historic';
import { SettingState } from '@/models/store/theme-editor';

const historic = useHistoric<SettingState>();
export const actions: ActionsInterface = {
  backup(context): void {
    const setting = context.rootState.themeEditor.setting;
    historic.createBackup(setting);
    console.log(context.rootState.themeEditor.setting);
  },
  undo(context): void {
    const setting = historic.setUndo();
    if (!setting) return;
    context.rootState.themeEditor.setting = setting;
  },
  redo(context): void {
    const setting = historic.setRedo();
    if (!setting) return;
    context.rootState.themeEditor.setting = setting;
  },
};
