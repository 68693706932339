/**
 * Font Awesome v.5
 *
 * Ícones disponíveis
 * https://fontawesome.com/icons?d=gallery&s=regular,solid&m=free
 */
import solidFonts from './solid';
import regularFonts from './regular';
import brandFonts from './brands';

/**
 * Display at: @features/general/icons
 */

import {
  library as fontawesome,
  IconDefinition,
} from '@fortawesome/fontawesome-svg-core';
export default {
  install(): void {
    const icons: IconDefinition[] = [
      ...solidFonts,
      ...regularFonts,
      ...brandFonts,
    ];
    fontawesome.add(...icons);
  },
};
