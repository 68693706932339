import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "input-file-preview" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "input-file-preview__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.type === 'image' && _ctx.url)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          loading: "lazy",
          src: _ctx.url,
          alt: _ctx.field,
          class: "input-file-preview__image",
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadImageError && _ctx.onLoadImageError(...args)))
        }, null, 40, _hoisted_2))
      : (_ctx.type === 'document')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            prefix: "fa",
            name: "file-invoice",
            "aria-hidden": "true",
            class: "input-file-preview__document-icon"
          }))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "input-file-preview__edit",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editFile && _ctx.editFile(...args)))
      }, [
        _createVNode(_component_Icon, {
          prefix: "far",
          name: "edit",
          "aria-hidden": "true",
          class: "input-file-preview__icon"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('defaults.inputs-file.edit-file')), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "input-file-preview__remove",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)))
      }, [
        _createVNode(_component_Icon, {
          prefix: "far",
          name: "trash-alt",
          "aria-hidden": "true",
          class: "input-file-preview__icon"
        })
      ])
    ])
  ]))
}