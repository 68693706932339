import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-remove-theme" }
const _hoisted_2 = { class: "modal-remove-theme__header" }
const _hoisted_3 = { class: "modal-remove-theme__text--title" }
const _hoisted_4 = { class: "modal-remove-theme__content" }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = { class: "modal-remove-theme__card-content" }
const _hoisted_7 = { class: "modal-remove-theme__text--theme-name" }
const _hoisted_8 = { class: "modal-remove-theme__card-wrapper" }
const _hoisted_9 = { class: "modal-remove-theme__text--theme-code" }
const _hoisted_10 = { class: "modal-remove-theme__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    "label-button": _ctx.$t('components.modals.button.remove'),
    title: _ctx.$t('components.modals.titles.attention'),
    "type-button": "delete",
    onDelete: _ctx.themeDelete
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('components.modals.remove-theme.title')), 1)
        ]),
        _createElementVNode("main", _hoisted_4, [
          _createElementVNode("img", {
            alt: _ctx.$t('components.modals.remove-theme.alt-image'),
            src: _ctx.theme.thumb,
            class: "modal-remove-theme__image"
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.theme.name || _ctx.$t('components.modals.remove-theme.theme-name')), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('components.modals.remove-theme.theme-code')) + " " + _toDisplayString(_ctx.theme.id || '--'), 1)
            ])
          ])
        ]),
        _createElementVNode("footer", _hoisted_10, [
          _createVNode(_component_Icon, {
            class: "modal-remove-theme__info--icon",
            prefix: "fas",
            name: "exclamation-triangle",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.modals.remove-theme.info')), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["label-button", "title", "onDelete"]))
}