import { BackupStorage } from './BackupStorage';
import { BackupCommand } from './BackupCommand';

export class BackupUndo<T> implements BackupCommand {
  constructor(private readonly backupStorage: BackupStorage<T>) {}

  execute(): void {
    this.backupStorage.undo();
  }

  undo(): void {
    this.backupStorage.redo();
  }
}
