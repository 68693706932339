type Params = {
  find: string;
  regexp: RegExp;
  replaceValue: any;
  value: string;
};

type UseRegExp = {
  /**
   * @name replace
   * @params Params - Parâmetros para a função.
   * @description Substitui os valores de uma string com base em um regexp.
   * @return string
   */
  replace(params: Params): string;
};

/**
 * @name useRegExp
 * @description Função reutilizável para expressões regulares.
 * @return UseRegExp
 */
export function useRegExp(): UseRegExp {
  function replace({ replaceValue, regexp, value, find }: Params): string {
    return value.includes(find) ? value.replace(regexp, replaceValue) : value;
  }
  return { replace };
}
