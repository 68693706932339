import { AxiosResponse } from 'axios';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { StructureSettingsApiClientUrlsInterface } from './StructureSettingsApiClientUrls.interface';
import { StructureSettingsApiClientInterface } from './StructureSettingsApiClient.interface';
import {
  Setting,
  SaveCurrentPayload,
} from '@/models/structure-settings/StructureSettings.interface';

export class StructureSettingsApiClientModel
  implements StructureSettingsApiClientInterface
{
  private readonly urls!: StructureSettingsApiClientUrlsInterface;

  constructor(urls: StructureSettingsApiClientUrlsInterface) {
    this.urls = urls;
  }

  getSettings(theme_id: string): Promise<AxiosResponse<Setting[]>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getSettings,
      requiresToken: false,
      payload: { theme_id },
    };
    return HttpClient.get(params);
  }

  saveCurrentTheme({
    theme_id,
    current,
  }: SaveCurrentPayload): Promise<AxiosResponse<any>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getSettings,
      requiresToken: false,
      payload: { theme_id, ...current },
    };
    return HttpClient.post(params);
  }
}
