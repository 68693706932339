import { CustomPage } from '@/models/custom-page/CustomPage.interface';
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { CustomPageApiClientUrlsInterface } from './CustomPageApiClientUrls.interface';
import { CustomPageApiClientInterface } from './CustomPageApiClient.interface';
import { IresponseThemeApi } from '@/models/list';

/**
 * @Name CustomPageApiClientModel
 * @description
 * Implementa a interface CustomPageApiClientInterface
 */
export class CustomPageApiClientModel implements CustomPageApiClientInterface {
  private readonly urls!: CustomPageApiClientUrlsInterface;

  constructor(urls: CustomPageApiClientUrlsInterface) {
    this.urls = urls;
  }

  index(): Promise<CustomPage[]> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.index,
      requiresToken: true,
      payload: '',
    };

    return HttpClient.get<IresponseThemeApi<any>>(params).then(
      (response) => response.data
    );
  }
}
