import { InputTextareaInterface } from './InputTextarea.interface';

export class InputTextarea implements InputTextareaInterface {
  public label = '';
  public type = '';
  public description = [];
  public placeholder = '';
  public field = '';
  public validation = '';
  public tab = '';
  public icon = '';
  public value = '';

  constructor(dto: InputTextareaInterface) {
    Object.assign(this, dto);
  }
}
