
import { InputSelectInterface, useInputSelect } from './module';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'InputSelect',
  props: {
    inputFields: {
      type: Object as PropType<InputSelectInterface>,
      required: true,
    },
  },
  emits: ['update-setting-current'],
  setup(props) {
    const { type, description, field, label, tab, options, value } =
      useInputSelect(
        props.inputFields.label,
        props.inputFields.type,
        props.inputFields.description,
        props.inputFields.field,
        props.inputFields.options,
        props.inputFields.tab,
        props.inputFields.value
      );
    const inputValue = ref(value);
    return {
      type,
      description,
      field,
      label,
      options,
      tab,
      value,
      inputValue,
    };
  },
  watch: {
    inputValue(value): void {
      this.emitInputData(value);
    },
  },
  methods: {
    emitInputData(value: string): void {
      this.$emit('update-setting-current', {
        key: this.field,
        value: value,
      });
    },
  },
});
