import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__input-toggle" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "tray-theme-editor__input-toggle__description" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.tab)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("label", {
          class: "tray-theme-editor__input-toggle__title",
          for: _ctx.field
        }, _toDisplayString(_ctx.label), 9, _hoisted_2),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.field,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        "true-value": "true",
        "false-value": "false",
        type: "checkbox",
        class: "tray-theme-editor__input-toggle__content"
      }, null, 8, _hoisted_4), [
        [_vModelCheckbox, _ctx.inputValue]
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}