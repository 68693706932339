import { Memento, ConcreteMemento } from '@/patterns/memento';
import { cloneDeep } from '@/plugins/lodash';

interface BackupInterface<T> {
  init(data: T): void;
  save(): Readonly<Memento>;
  restore(memento: Memento): void;
  getData(): T;
}

export class Backup<T> implements BackupInterface<T> {
  private currentBackup = {} as T;

  init(data: T): void {
    this.currentBackup = cloneDeep(data);
  }

  save(): Readonly<Memento> {
    const date = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const backup = cloneDeep(this.currentBackup);
    const name = `Backup:${date}`;
    return new ConcreteMemento<T>(name, date, backup);
  }

  restore(memento: Memento | undefined): void {
    if (!memento) {
      this.currentBackup = {} as T;
      return;
    }
    this.currentBackup = memento.getData();
  }

  getData(): T {
    return this.currentBackup;
  }
}
