import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tooltip", `tooltip--${_ctx.theme} tooltip--${_ctx.position}`])
  }, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    _createElementVNode("span", {
      class: _normalizeClass(["tooltip__arrow", `tooltip__arrow--${_ctx.theme} tooltip__arrow--${_ctx.position}`])
    }, null, 2)
  ], 2))
}