import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "changelog-theme" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeLogThemeInfo = _resolveComponent("ChangeLogThemeInfo")!
  const _component_ChangeLogList = _resolveComponent("ChangeLogList")!
  const _component_ChangeLogListEmpty = _resolveComponent("ChangeLogListEmpty")!
  const _component_SideBar = _resolveComponent("SideBar")!

  return (_openBlock(), _createBlock(_component_SideBar, {
    ref: "sidebar",
    title: _ctx.$t('components.sidebar.changelog.title'),
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_ChangeLogThemeInfo, _normalizeProps(_guardReactiveProps(_ctx.theme)), null, 16),
        (_ctx.hasChanges)
          ? (_openBlock(), _createBlock(_component_ChangeLogList, {
              key: 0,
              changelog: _ctx.changelog
            }, null, 8, ["changelog"]))
          : (_openBlock(), _createBlock(_component_ChangeLogListEmpty, { key: 1 }))
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}