
import ChangeLogTheme from '@/components/sidebar/changelog-theme/ChangeLog.vue';
import RemoveThemeModal from '@/components/modals/remove-theme/RemoveThemeModal.vue';
import EditThemeModal from '@/components/modals/edit-theme/EditThemeModal.vue';
import NavigationThemeList from '@/components/NavigationThemeList.vue';
import UnpublishedThemeList from '@/components/list/theme/ThemeListTemplate.vue';
import ThemeDisplayEmpty from '@/components/theme-display-empty/ThemeDisplayEmpty.vue';
import ThemeDisplay from '@/components/list/published/ThemeDisplayTemplate.vue';
import ThemeLogoSeals from '@/components/theme-logo-seals/ThemeLogoSeals.vue';
import LoadingPage from '@/components/LoadingPage.vue';
import BuyThemeModal from '@/components/modals/buy-theme/BuyThemeModal.vue';
import RenameThemeModal from '@/components/modals/rename-theme/RenameThemeModal.vue';
import Notification from '@/components/notifications/Notification.vue';
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { Timers } from '@/enums';
import { IthemeDataListingStore } from '@/models/store/theme-editor';
import { config } from '@/config';
import AlertBeta from '@/components/list/AlertBeta.vue';

export default defineComponent({
  name: 'ThemeListPage',
  components: {
    NavigationThemeList,
    UnpublishedThemeList,
    ThemeDisplayEmpty,
    ThemeDisplay,
    RemoveThemeModal,
    EditThemeModal,
    LoadingPage,
    ThemeLogoSeals,
    ChangeLogTheme,
    BuyThemeModal,
    RenameThemeModal,
    Notification,
    AlertBeta,
  },
  data() {
    return {
      label: {
        themePublished: '',
        themeUnpublished: '',
      },
      selectedTheme: {} as IthemeDataListingStore,
      showLoading: false,
      loadList: false,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      themes: 'themeList',
      theme: 'themeGetPublished',
      themeSelected: 'themeGetSelected',
      themeLoading: 'themeLoading',
      initialLoading: 'initialLoading',
      changeLogList: 'changeLogsList',
    }),
    ...mapGetters('user', {
      externalUrl: 'urlsGet',
    }),
    isTheme(): boolean {
      return Object.keys(this.theme).length > 0;
    },
    isPublishedTheme(): boolean {
      return this.theme.published;
    },
    isThemeList(): boolean {
      return this.themes.length > 0;
    },
    labels(): {
      themePublished: string;
      themeUnpublished: string;
    } {
      return this.label;
    },
    duration(): number {
      return Timers.AWAIT4000MS;
    },
  },
  watch: {
    isTheme: {
      handler(theme: boolean): void {
        if (!theme) return;
        this.activeSkelleton();
      },
      immediate: true,
    },
    theme: {
      handler(): void {
        this.navigateToTop();
      },
      deep: true,
    },
  },
  mounted() {
    this.initLoadDataThemeList();
  },
  methods: {
    ...mapActions('themeEditor', {
      initLoadDataThemeList: 'initLoadDataThemeList',
      apiThemeRemove: 'apiThemeRemove',
      apiThemePublish: 'apiThemePublish',
      apiThemeDuplicate: 'apiThemeDuplicate',
      apiThemeUpdateName: 'apiThemeUpdateName',
      themeSelect: 'themeSelect',
      apiThemeChangeLog: 'apiThemeChangeLog',
      loadMorePages: 'loadMorePagesListing',
    }),
    viewStore(theme: IthemeDataListingStore): void {
      window.open(theme.preview, '_blank');
    },
    themeEditingContinue(): void {
      if (this.selectedTheme.has_structure) {
        this.$router.push({
          name: 'Home',
          params: {
            id: this.selectedTheme.id,
            page: 'home',
          },
        });
        return;
      }
      const urlOldOpencode = `https://opencode.tray.com.br/v2/themes/${this.selectedTheme.entity_id}/customizations`;
      window.open(urlOldOpencode, '_blank');
    },
    themeEdit(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      if (theme.published) {
        this.openModalEditTheme(theme);
        return;
      }
      this.themeEditingContinue();
    },
    themeHtmlEdit(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      const urlHtmlOldOpencode = `https://opencode.tray.com.br/v2/themes/${this.selectedTheme.entity_id}/editor`;
      window.open(urlHtmlOldOpencode, '_blank');
    },
    async themeChangeLog(theme: IthemeDataListingStore): Promise<void> {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      await this.apiThemeChangeLog();
      this.$refs.sidebar.open();
    },
    async themeDuplicate(theme: IthemeDataListingStore): Promise<void> {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      try {
        this.selectedTheme = theme;
        this.themeSelect(theme.id);
        await this.apiThemeDuplicate();
        this.openNotification('duplicate');
      } catch (e) {
        this.openNotificationError('duplicate');
      }
    },
    themeUpdatedVersion(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      const urlUpdateOldOpencode = `${config.theme.updateTheme}/${this.selectedTheme.app_theme}/setup/pre_install?base=${this.selectedTheme.entity_id}#`;

      window.open(urlUpdateOldOpencode, '_blank');
    },
    openModalRemove(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      this.$refs.modalDeleteTheme.open();
    },
    openModalRename(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      this.$refs.modalRename.open();
      this.apiThemeUpdateName();
    },
    openModalEditTheme(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.$refs.modalEditTheme.open();
    },
    resetSelectedTheme(): void {
      this.selectedTheme = {};
    },
    async themeDelete(isRemove: boolean): Promise<void> {
      if (!isRemove) {
        this.resetSelectedTheme();
        return;
      }
      try {
        await this.apiThemeRemove(this.selectedTheme.id);
        this.resetSelectedTheme();
        this.openNotification('delete');
      } catch (e) {
        this.openNotificationError('delete');
      }
    },
    openModalBuyTheme(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      this.$refs.modalBuyTheme.open();
    },
    themeBuy(): void {
      const url = `${config.theme.buyTheme}/${this.selectedTheme.entity_id}/billings`;
      window.open(url, '_blank');
    },
    openChangePayment(theme: IthemeDataListingStore): void {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      window.open(this.selectedTheme.payment.link, '_blank');
    },
    async themePublish(theme: IthemeDataListingStore): Promise<void> {
      this.selectedTheme = theme;
      this.themeSelect(theme.id);
      await this.apiThemePublish();
    },
    themePreview(): void {
      return;
    },
    activeSkelleton(): void {
      this.label.themePublished = this.$t(
        'pages.theme-list.labels.published-theme'
      );
      this.label.themeUnpublished = this.$t(
        'pages.theme-list.labels.installed-and-unpublished-themes'
      );
    },
    navigateToTop(): void {
      const main = document.getElementById('published-theme');
      main?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
    openNotification(action: string): void {
      let titleNotify;
      let descriptionNotify;

      if (action == 'duplicate') {
        titleNotify = this.$t('components.notification.duplicate-theme');
        descriptionNotify = this.$t(
          'components.notification.success-duplicate'
        );
      }

      if (action == 'delete') {
        titleNotify = this.$t('components.notification.delete-theme');
        descriptionNotify = this.$t('components.notification.success-delete');
      }

      this.$refs.notification.open({
        title: titleNotify,
        description:
          this.$t('components.notification.theme') +
          ' ' +
          this.themeSelected.name +
          ' ' +
          descriptionNotify,
        type: 'success',
      });
    },
    openNotificationError(action: string): void {
      let titleNotify;
      let descriptionNotify;

      if (action == 'duplicate') {
        titleNotify = this.$t('components.notification.duplicate-theme');
        descriptionNotify = this.$t('components.notification.error-duplicate');
      }

      if (action == 'delete') {
        titleNotify = this.$t('components.notification.delete-theme');
        descriptionNotify = this.$t('components.notification.error-delete');
      }
      this.$refs.notification.open({
        title: titleNotify,
        description: descriptionNotify,
        type: 'error',
      });
    },
  },
});
