import { AlertInterface } from './Alert.interface';

export class Alert implements AlertInterface {
  public label = '';
  public type = '';
  public description = [];
  public field = '';
  public link = '';
  public url = '';

  constructor(dto: AlertInterface) {
    Object.assign(this, dto);
  }
}
