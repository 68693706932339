import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { DataLayerApiClientUrlsInterface } from './DataLayerApiClientUrls.interface';
import { DataLayerApiClientInterface } from './DataLayerApiClient.interface';
import { DataLayerInfo } from '@/models/data-layer/DataLayer.interface';

/**
 * @Name DataLayerApiClientModel
 * @description
 * Implementa a interface DataLayerApiClientInterface
 */
export class DataLayerApiClientModel implements DataLayerApiClientInterface {
  private readonly urls!: DataLayerApiClientUrlsInterface;

  constructor(urls: DataLayerApiClientUrlsInterface) {
    this.urls = urls;
  }

  getStoreInfo(): Promise<DataLayerInfo> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getStoreInfo,
      requiresToken: false,
      payload: '',
    };

    return HttpClient.get(params);
  }
}
