import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faDesktop,
  faEdit,
  faHome,
  faMobileAlt,
  faTabletAlt,
  faArrowsAltH,
  faLevelUpAlt,
  faLevelDownAlt,
  faUndo,
  faPlay,
  faTags,
  faFillDrip,
  faFolderPlus,
  faCog,
  faShoppingBag,
  faInfoCircle,
  faCartPlus,
  faList,
  faStoreSlash,
  faCircleNotch,
  faEllipsisV,
  faShoppingCart,
  faUpload,
  faSearch,
  faSwatchbook,
  faWindowMinimize,
  faShareAlt,
  faStore,
  faBars,
  faColumns,
  faWindowMaximize,
  faPaperPlane,
  faCommentDots,
  faExclamationTriangle,
  faTimes,
  faFileUpload,
  faFileInvoice,
  faSearchPlus,
  faEnvelope,
  faPhone,
  faHeadset,
  faSync,
  faSmileWink,
} from '@fortawesome/free-solid-svg-icons';

const iconsSolid = [
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faDesktop,
  faEdit,
  faHome,
  faMobileAlt,
  faTabletAlt,
  faArrowsAltH,
  faLevelUpAlt,
  faLevelDownAlt,
  faUndo,
  faPlay,
  faTags,
  faFillDrip,
  faFolderPlus,
  faCog,
  faShoppingBag,
  faInfoCircle,
  faCartPlus,
  faList,
  faStoreSlash,
  faCircleNotch,
  faEllipsisV,
  faShoppingCart,
  faUpload,
  faSearch,
  faSwatchbook,
  faWindowMinimize,
  faShareAlt,
  faStore,
  faBars,
  faColumns,
  faWindowMaximize,
  faPaperPlane,
  faCommentDots,
  faExclamationTriangle,
  faTimes,
  faFileUpload,
  faFileInvoice,
  faSearchPlus,
  faEnvelope,
  faPhone,
  faHeadset,
  faSync,
  faSmileWink,
] as IconDefinition[];

export default iconsSolid;
