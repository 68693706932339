import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';
import { ThemeApiClientUrlsInterface, ThemeApiClientInterface } from './';
import { Info } from '@/models/structure-settings/StructureSettings.interface';
import {
  ChangelogTheme,
  IchangeLog,
  IchangeLogDataApi,
} from '@/models/change-log/';
import {
  IthemeDataApi,
  IresponseThemeApi,
  ListingThemesModel,
  ThemeListingData,
  IgetListThemeApi,
} from '@/models/list';
import {
  IgetByIdThemeApi,
  EditorThemeModel,
  ThemePreviewModel,
  IfetchThemeApi,
  ThemeInfoModel,
} from '@/models/editor';

/**
 * @Name ThemeApiClientModel
 * @description
 * Implementa a interface ThemeApiClientInterface
 */
export class ThemeApiClientModel implements ThemeApiClientInterface {
  private readonly urls!: ThemeApiClientUrlsInterface;
  private page: string | undefined;
  private hasMorePages: boolean;

  constructor(urls: ThemeApiClientUrlsInterface) {
    this.urls = urls;
    this.page = '';
    this.hasMorePages = false;
  }

  /**
   * @name handleMorePages
   * @description Verifica se o parâmetro passado equivale a uma página na URL e
   * retorna o query parameter necessário para requisição e um valor bollean para a
   * variável que define se tem mais páginas a serem carregadas na api
   * @param nextPageUrl - next_page_url retornado na requisição getList
   * @returns Query parameter da paginação do getList, ou string vazia
   */
  private handleMorePages(nextPageUrl: string | null): string | undefined {
    if (nextPageUrl != '' && nextPageUrl != 'api') {
      this.page = nextPageUrl?.substring(14);
      this.hasMorePages = true;
      return this.page;
    }
    this.hasMorePages = false;
    return (this.page = '');
  }

  /**
   * @name hasMorePagesListing
   * @description Valida se existem mais páginas para serem exibidas na LISTAGEM
   * @returns {boolean}
   */
  hasMorePagesListing(): boolean {
    return this.hasMorePages;
  }

  /**
   * @name resetMorePagesListing
   * @description Reseta a variável "hasMorePages" no caso da LISTAGEM precisar ser
   * recarregada
   */
  resetMorePagesListing(): void {
    this.hasMorePages = false;
  }

  /**
   * @name getList
   * @description Recupera 16 temas para LISTAGEM, validando se existem mais
   * paginas para serem exibidas, caso possua recupera os temas da proxima página
   */
  getList(): Promise<ListingThemesModel> {
    let params: HttpRequestParamsInterface;

    if (this.hasMorePages) {
      const urlPage = `${this.urls.getList}${this.page}`;
      params = {
        url: urlPage,
        requiresToken: true,
      };
    } else {
      params = {
        url: this.urls.getList,
        requiresToken: true,
      };
    }

    return HttpClient.get<IresponseThemeApi<IgetListThemeApi>>(params).then(
      (response) => {
        const themes = response.data.data.map(
          (theme) => new ThemeListingData(theme)
        );
        this.handleMorePages(response.data.next_page_url);

        response.data.data = themes;
        return response;
      }
    );
  }

  /**
   * @name removeTheme
   * @description Remove o tema selecionado da LISTAGEM
   * @param idTheme identificador único do tema
   * @returns resultado da requisição
   */
  removeTheme(idTheme: string): Promise<IthemeDataApi> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.removeTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<IthemeDataApi>(params).then((response) => response);
  }

  /**
   * @name publishTheme
   * @description Publica o tema selecionado na LISTAGEM
   * @param {number} idTheme identificador único do tema
   * @returns resultado da requisição
   */
  publishTheme(idTheme: number): Promise<IthemeDataApi> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.publishTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<IthemeDataApi>(params).then((response) => response);
  }

  /**
   * @name duplicateTheme
   * @description Duplica o tema selecionado na LISTAGEM
   * @param idTheme - identificador único do tema
   * @returns {IthemeDataApi}
   */
  duplicateTheme(idTheme: number): Promise<IthemeDataApi> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.duplicateTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<IthemeDataApi>(params).then((response) => response);
  }

  /**
   * @name updateName
   * @description Pega tema e o nome desejado e o renomeia na LISTAGEM
   * @param payload.id - identificador único do tema
   * @param payload.name - novo nome para o tema
   */
  updateName(payload: { id: number; name: string }): Promise<IthemeDataApi> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.updateName + '/' + payload.id.toString() + '/name',
      requiresToken: true,
      payload: { name: payload.name },
    };
    return HttpClient.post<IthemeDataApi>(params).then((response) => response);
  }

  /**
   * @name getThemeById
   * @description Pega um tema por ID com os dados para o EDITOR
   * @param {number} idTheme identificador único do tema
   * @returns {IgetByIdThemeApi}
   */
  getThemeById(idTheme: number): Promise<EditorThemeModel> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getThemeById,
      requiresToken: true,
      payload: { theme_id: idTheme },
    };
    return HttpClient.get<IresponseThemeApi<IgetByIdThemeApi>>(params).then(
      (response) => response
    );
  }

  /**
   * @name fetchTheme
   * @description Pega a url e template do tema para gerar o preview no EDITOR.
   * @param {string} url Link do preview do tema com parametro
   * @returns {ThemePreviewModel}
   */
  fetchTheme(url: string): Promise<ThemePreviewModel> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.fetchTheme,
      requiresToken: false,
      payload: { url },
    };

    return HttpClient.get<IresponseThemeApi<IfetchThemeApi>>(params).then(
      (response) => response
    );
  }

  /**
   * @name getInfo
   * @description Pega a version, documentation e support do tema para exibir no
   * header do EDITOR
   * @param idTheme
   * @returns {Info}
   */
  getInfo(idTheme: string): Promise<ThemeInfoModel> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getInfo,
      requiresToken: false,
      payload: { theme_id: idTheme },
    };
    return HttpClient.get<IresponseThemeApi<Info>>(params).then(
      (response) => response
    );
  }

  getChangeLog(themeId: number): Promise<IchangeLog[]> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getChangeLog,
      requiresToken: true,
      payload: { theme_id: themeId },
    };
    return HttpClient.get<IresponseThemeApi<any>>(params).then((response) => {
      response = JSON.parse(response.data);
      if (!response.data.message) {
        const newLogs = response.data.map(
          (log: IchangeLogDataApi) => new ChangelogTheme(log)
        );
        return newLogs;
      }
      return [];
    });
  }
}
