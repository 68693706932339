/**
 * @enum {number} Valores de "action" (tipo de ação) do changelog vindo da api
 * @param CREATE - Tema criado pelos comandos do Tray CLI
 * @param UPDATED - Tema customizado alguma de suas configurações no editor
 * @param PUBLISHED - Tema publicado
 * @param UNPUBLISHED - Tema removido da publicação
 * @param REMOVED - Tema excluido
 * @param DUPLICATE - Tema duplicado
 * @param RENAMED - Tema renomeado
 * @param CREATE_BY_UPDATE - Tema criado a partir de uma atualização
 * @param CREATE_BY_STORE - Tema criado a partir da instalação pela Loja de temas
 * @param DOWNLOAD_ZIP - Tema efetuado download dos arquivos em formato zip
 * @param UPLOAD_ZIP - Tema efetuado upload dos arquivos em formato zip
 */
export enum ChangeTypeApi {
  CREATE = 1,
  UPDATED = 2,
  PUBLISHED = 3,
  UNPUBLISHED = 4,
  REMOVED = 5,
  DUPLICATE = 6,
  RENAMED = 7,
  CREATE_BY_UPDATE = 8,
  CREATE_BY_STORE = 9,
  DOWNLOAD_ZIP = 10,
  UPLOAD_ZIP = 11,
}
