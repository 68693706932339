
import Notification from '@/components/notifications/Notification.vue';
import { defineComponent } from 'vue';
import RenameTheme from './RenameTheme.vue';
import BaseModal from '@/components/modals/base-modal/BaseModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'RenameThemeModal',
  components: {
    BaseModal,
    Notification,
    RenameTheme,
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      selectedTheme: 'themeGetSelected',
    }),
    isDisableBtnSave(): boolean {
      return this.name.length === 0;
    },
  },
  methods: {
    ...mapActions('themeEditor', { updateThemeName: 'apiThemeUpdateName' }),
    open(): void {
      this.$refs.modal.open();
    },
    save(): void {
      this.updateThemeName(this.name)
        .then(() => {
          this.updateSuccess(this.name);
        })
        .catch(() => {
          this.updateError(this.name);
        });
    },
    updateSuccess(name: string): void {
      this.$refs.notification.open({
        title: this.$t('components.modals.modal-rename-success.title'),
        description:
          this.$t('components.modals.modal-rename-success.text-rename') + name,
        type: 'success',
      });
    },
    updateError(): void {
      this.$refs.notification.open({
        title: this.$t('components.modals.modal-rename-error.title'),
        description: '',
        type: 'error',
      });
    },
  },
});
