
import { useRegExp } from '@/support';
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default defineComponent({
  name: 'IframePreview',
  setup() {
    const previewIframe = ref<HTMLIFrameElement>(
      document.createElement('iframe')
    );
    return {
      previewIframe,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      activeResolution: 'previewSelectedResolution',
      previewTemplate: 'previewGetTemplate',
    }),
    templatePageHome(): string {
      const template = this.previewTemplate;
      return this.convertStringIntoDocument(template, true);
    },
    templatePagePreview(): string {
      const template = this.previewTemplate;
      return this.convertStringIntoDocument(template, false);
    },
  },
  watch: {
    activeResolution: {
      handler(resolution): void {
        this.previewIframe.width = resolution.width;
        this.previewIframe.height = resolution.height;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.loadIframe();
  },
  methods: {
    ...mapActions('themeEditor', {
      changePage: 'apiPageChangePagePreview',
    }),
    parseLinkIframe(link: string): string {
      const regexp = useRegExp();
      const removeBarLink = regexp.replace({
        regexp: /\//,
        replaceValue: '',
        find: '/',
        value: link,
      });
      const removeHashLink = regexp.replace({
        regexp: /-/g,
        replaceValue: ' ',
        find: '-',
        value: removeBarLink,
      });
      return removeHashLink[0].toUpperCase() + removeHashLink.substring(1);
    },
    loadIframe(): void {
      const iframe = this.$refs.templatePagePreview.contentWindow.document.body;
      iframe.addEventListener('click', (event: any) => {
        event.preventDefault();
        const url = this.getUrlFromClickEvent(event);
        if (!url) {
          return;
        }
        const { pathname, host } = new URL(url);
        if (!host.includes('commercesuite')) return;
        if (pathname.includes('reset')) return;
        this.changePage({
          slug: 'home',
          name: this.parseLinkIframe(pathname),
          url: pathname,
          active: '1',
          template: '',
          id: '',
          conteudo: '',
          info: '',
          metatags: '',
          seo: '',
          title: '',
        });
      });
    },
    getUrlFromClickEvent(event: MouseEvent): string {
      const target = event.target as HTMLInputElement;
      let url =
        target.nodeName == 'A' ? (event.target as HTMLAnchorElement).href : '';
      if (url.length == 0) {
        url = (target.parentElement as HTMLAnchorElement).href;
      }
      return typeof url == 'string' ? url : '';
    },
    convertStringIntoDocument(template: string, disableClick: boolean): string {
      const parser = new DOMParser();
      const doc = parser.parseFromString(template, 'text/html');
      this.addCrossOriginInScripts(doc);
      this.disableClickEventsInThemePreview(doc, disableClick);
      return doc.documentElement.outerHTML;
    },
    addCrossOriginInScripts(doc: Document): void {
      doc.querySelectorAll('script').forEach((script) => {
        script.crossOrigin = 'anonymous';
      });
    },
    disableClickEventsInThemePreview(doc: Document, click: boolean): void {
      doc.body.style.pointerEvents = click ? 'none' : 'auto';
    },
  },
});
