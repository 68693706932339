
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ZoomImageModal',
  components: {
    Icon,
  },
  props: {
    urlImage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open(): void {
      this.show = true;
    },
    close(): void {
      this.show = false;
    },
  },
});
