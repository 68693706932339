import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["tray-theme-editor__icon", { 'tray-theme-editor__icon--flip': _ctx.flip }])
  }, [
    _createVNode(_component_FontAwesomeIcon, {
      icon: [_ctx.prefix, _ctx.name],
      style: _normalizeStyle({
        transform: `rotate(${_ctx.rotate}deg)`,
      })
    }, null, 8, ["icon", "style"])
  ], 2))
}