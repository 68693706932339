import { ref, Ref } from 'vue';

type UseScrollPosition = {
  /**
   * @name scrollTop
   * @description Armazena os valores da posição do scroll.
   * @type number
   */
  scrollTop: Ref<number>;

  /**
   * @name savePosition
   * @description Salva a posição do elemento na variável scrollTop.
   * @return void
   */
  savePosition: () => void;
  /**
   * @name restorePosition
   * @description Restaura a posição do elemento.
   * @return void
   */
  restorePosition: () => void;
  /**
   * @name listenForElementScrollEvent
   * @description Escuta os eventos de scroll e armazena o valor na variável scrollTop.
   * @return void
   */
  listenForElementScrollEvent: () => void;
};

/**
 * @name useScrollPosition
 * @description Função para capturar, salvar e restaurar a posição do scroll de um elemento.
 * @param element Ref<HTMLElement>
 * @return UseScrollPosition
 */
export function useScrollPosition(
  element: Ref<HTMLElement>
): UseScrollPosition {
  const scrollTop = ref(0);

  function savePosition(): void {
    scrollTop.value = element.value.scrollTop;
  }
  function restorePosition(): void {
    element.value.scrollTop = scrollTop.value;
  }
  function listenForElementScrollEvent(): void {
    scrollTop.value = element.value.scrollTop;
  }

  return {
    scrollTop,
    savePosition,
    restorePosition,
    listenForElementScrollEvent,
  };
}
