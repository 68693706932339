import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "unpublished-theme" }
const _hoisted_2 = { class: "unpublished-theme__header" }
const _hoisted_3 = { class: "unpublished-theme__content" }
const _hoisted_4 = { class: "unpublished-theme__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemoveOldThemesModal = _resolveComponent("RemoveOldThemesModal")!
  const _component_ThemeSearchBar = _resolveComponent("ThemeSearchBar")!
  const _component_ThemeItem = _resolveComponent("ThemeItem")!
  const _component_ThemeDisplayEmpty = _resolveComponent("ThemeDisplayEmpty")!
  const _component_ThemeAlertLimit = _resolveComponent("ThemeAlertLimit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RemoveOldThemesModal, {
      ref: "modalDeleteOldThemes",
      title: _ctx.$t('components.modals.titles.attention')
    }, null, 8, ["title"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        (_openBlock(), _createBlock(_component_ThemeSearchBar, {
          key: _ctx.componentKey,
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
        }, null, 8, ["modelValue"]))
      ]),
      _createElementVNode("main", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themesVisibleOrSearchByNameOrCode, (theme, index) => {
          return (_openBlock(), _createBlock(_component_ThemeItem, _mergeProps({ key: index }, theme, {
            onThemeEdit: ($event: any) => (_ctx.themeEdit(theme)),
            onThemeHtmlEdit: ($event: any) => (_ctx.themeHtmlEdit(theme)),
            onThemeRename: ($event: any) => (_ctx.themeRename(theme)),
            onThemeChangeLog: ($event: any) => (_ctx.themeChangeLog(theme)),
            onThemeDuplicate: ($event: any) => (_ctx.themeDuplicate(theme)),
            onThemeUpdatedVersion: ($event: any) => (_ctx.themeUpdatedVersion(theme)),
            onThemeDelete: ($event: any) => (_ctx.themeDelete(theme)),
            onThemeBuy: ($event: any) => (_ctx.themeBuy(theme)),
            onThemeChangePayment: ($event: any) => (_ctx.themeChangePayment(theme)),
            onThemePublish: ($event: any) => (_ctx.themePublish(theme)),
            onThemePreview: ($event: any) => (_ctx.themePreview(theme))
          }), null, 16, ["onThemeEdit", "onThemeHtmlEdit", "onThemeRename", "onThemeChangeLog", "onThemeDuplicate", "onThemeUpdatedVersion", "onThemeDelete", "onThemeBuy", "onThemeChangePayment", "onThemePublish", "onThemePreview"]))
        }), 128)),
        (_ctx.showSearchNoThemes)
          ? (_openBlock(), _createBlock(_component_ThemeDisplayEmpty, {
              key: 0,
              label: _ctx.$t('components.theme-display-empty.no-themes-found'),
              "button-theme-store": false,
              onClearFilter: _ctx.clearFilter
            }, null, 8, ["label", "onClearFilter"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("footer", _hoisted_4, [
        _createVNode(_component_ThemeAlertLimit, {
          "hidden-see-all-themes": !_ctx.showMoreThemesButton,
          "number-of-themes": _ctx.themes.length,
          onSeeAllThemes: _ctx.showMoreThemes,
          onRemoveOldThemes: _ctx.openModalRemoveOld
        }, null, 8, ["hidden-see-all-themes", "number-of-themes", "onSeeAllThemes", "onRemoveOldThemes"]),
        (_ctx.showMoreThemesButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              "data-button": "verMais",
              "test-id": "show-more-items",
              class: "unpublished-theme__footer-link",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showMoreThemes && _ctx.showMoreThemes(...args)))
            }, _toDisplayString(_ctx.$t('components.unpublished-list-themes.button-more-themes')), 1))
          : (_ctx.showLessThemesButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                "data-button": "verMenos",
                "test-id": "hidden-more-items",
                class: "unpublished-theme__footer-link",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showLessThemes && _ctx.showLessThemes(...args)))
              }, _toDisplayString(_ctx.$t('components.unpublished-list-themes.button-less-themes')), 1))
            : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}