import { InputEmail } from './InputEmail.model';

function useInputEmail(
  label: string,
  type: string,
  description: string[],
  placeholder: string,
  field: string,
  validation: string,
  value: string
): InputEmail {
  return new InputEmail({
    label,
    type,
    description,
    placeholder,
    field,
    validation,
    value,
  });
}

export { useInputEmail };
