import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__theme-info" }
const _hoisted_2 = { class: "tray-theme-editor__theme-info-span" }
const _hoisted_3 = { class: "tray-theme-editor__theme-info-name" }
const _hoisted_4 = { class: "tray-theme-editor__theme-info-version" }
const _hoisted_5 = {
  key: 0,
  class: "tray-theme-editor__theme-info-button opacity"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 2,
  class: "tray-theme-editor__theme-info-button opacity"
}
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('components.theme-info.info')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.theme.name), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('components.theme-info.version')) + " " + _toDisplayString(_ctx.info.version), 1),
    _createElementVNode("div", null, [
      (!_ctx.info.documentation)
        ? (_openBlock(), _createElementBlock("a", _hoisted_5, _toDisplayString(_ctx.$t('components.theme-info.doc-theme')), 1))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "tray-theme-editor__theme-info-button",
            href: _ctx.info.documentation,
            target: "_blank"
          }, _toDisplayString(_ctx.$t('components.theme-info.doc-theme')), 9, _hoisted_6)),
      (!_ctx.info.support)
        ? (_openBlock(), _createElementBlock("a", _hoisted_7, _toDisplayString(_ctx.$t('components.theme-info.support-theme')), 1))
        : (_openBlock(), _createElementBlock("a", {
            key: 3,
            class: "tray-theme-editor__theme-info-button",
            href: _ctx.info.support,
            target: "_blank"
          }, _toDisplayString(_ctx.$t('components.theme-info.support-theme')), 9, _hoisted_8))
    ])
  ]))
}