import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { noBackThemeListIfProd } from '@/router/middleware';
import { useEnv } from '@/support';

import Editor from '@/layouts/Editor.vue';
import ThemeListPage from '@/pages/ThemeListPage.vue';
import PreFetchPage from '@/pages/PreFetchPage.vue';
const HomePage = (): any => import('@/pages/HomePage.vue');
const PreviewPage = (): any => import('@/pages/PreviewPage.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'PreFetchPage',
    component: PreFetchPage,
  },
  {
    path: '/themes',
    name: 'ThemeListPage',
    component: ThemeListPage,
  },
  {
    path: '/editor',
    name: 'Editor',
    component: Editor,
    children: [
      {
        path: '/editor/:id/:page',
        name: 'Home',
        component: HomePage,
      },
      {
        path: '/editor/:id/:page/preview',
        name: 'Pré visualização',
        component: PreviewPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/theme-editor'),
  routes,
});

router.beforeEach((to, from, next) => {
  const { isActiveEnvIs } = useEnv();
  noBackThemeListIfProd(to, next, isActiveEnvIs('live'));
});

export default router;
