import { InputEmailInterface } from './InputEmail.interface';

export class InputEmail implements InputEmailInterface {
  public label = '';
  public type = '';
  public description = [];
  public placeholder = '';
  public field = '';
  public validation = '';
  public value = '';

  constructor(dto: InputEmailInterface) {
    Object.assign(this, dto);
  }
}
