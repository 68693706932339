
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThemeDisplayStatus',
  props: {
    updated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    descriptionStatus(): string {
      return this.updated
        ? this.$t(
            'components.theme-display.status.your-theme-has-the-updated-version'
          )
        : this.$t('components.theme-display.status.update-your-theme-version');
    },
  },
});
