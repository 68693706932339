import { Ref } from 'vue';

/**
 * @name useRemoveLastSeparator
 * @description Função para esconder a ultima tag HR de uma lista de Hrs.
 * @param {element} [element] Ref<HTMLElement>
 * @returns {void}
 */
export const useRemoveLastSeparator = (element?: Ref<HTMLElement>): void => {
  let el;

  if (element) {
    el = element.value;
  } else {
    el = document;
  }

  const hrs = el.getElementsByClassName('separator-hr');
  if (!hrs.length) return;
  const lastHr = hrs.item(hrs.length - 1);
  if (!lastHr) return;
  lastHr.setAttribute('style', 'display:none;');
};
