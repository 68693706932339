import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "status-save-info" }
const _hoisted_2 = {
  key: 0,
  class: "status-save-info__status-save"
}
const _hoisted_3 = { class: "status-save-info__wrapper" }
const _hoisted_4 = { class: "status-save-info__message-success" }
const _hoisted_5 = {
  key: 1,
  class: "status-save-info__status-error"
}
const _hoisted_6 = { class: "status-save-info__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'initial')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            prefix: "far",
            name: "check-circle",
            class: "tray-theme-editor__icon--icon-save"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('components.status-save.info.success.message')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'error')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Icon, {
            prefix: "far",
            name: "times-circle",
            class: "tray-theme-editor__icon--icon-error"
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.status-save.info.error.message')), 1),
            _createElementVNode("a", {
              class: "status-save-info__link",
              href: "#",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('try-again')))
            }, _toDisplayString(_ctx.$t('components.status-save.info.error.try-again')), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}