import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "externalWebsite",
  class: "tray-theme-editor__external-website"
}
const _hoisted_2 = { class: "tray-theme-editor__external-website-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditThemeModal = _resolveComponent("EditThemeModal")!
  const _component_LoadingOpencode = _resolveComponent("LoadingOpencode")!
  const _component_IframePreview = _resolveComponent("IframePreview")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EditThemeModal, {
      ref: "modalEditTheme",
      onContinue: _ctx.themeEdit
    }, null, 8, ["onContinue"]),
    _createElementVNode("section", _hoisted_1, [
      _withDirectives(_createVNode(_component_LoadingOpencode, null, null, 512), [
        [_vShow, _ctx.isLoadingIframe]
      ]),
      _renderSlot(_ctx.$slots, "custom-resolution-pages"),
      _createVNode(_component_IframePreview),
      _withDirectives(_createElementVNode("section", _hoisted_2, [
        _createElementVNode("button", {
          class: "tray-theme-editor__external-website__handle-width",
          type: "button",
          onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initDrag && _ctx.initDrag(...args)))
        }, [
          _createVNode(_component_Icon, {
            class: "tray-theme-editor__icon",
            name: "chevron-left",
            prefix: "fas"
          })
        ], 32),
        _createElementVNode("button", {
          class: "tray-theme-editor__external-website__handle-height",
          type: "button",
          onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.initDrag && _ctx.initDrag(...args)))
        }, [
          _createVNode(_component_Icon, {
            class: "tray-theme-editor__icon",
            name: "chevron-up",
            prefix: "fas"
          })
        ], 32)
      ], 512), [
        [_vShow, _ctx.isCustomDrag]
      ])
    ], 512)
  ], 64))
}